import React from "react";
import "./Spinner.css";

const Spinner = (props) => {
  const { small } = props;

  return (
    <div className={small ? "lds-small" : "lds-wrapper"}>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className="spinner-text ml-0">Loading</div>
    </div>
  );
};

export default Spinner;
