import { baseURL } from "utils/constants";
import { checkAuth } from "hooks/checkAuth";
import { setErrorData } from "helpers/queryHelpers";

export async function getUsers() {
  await checkAuth();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
  };

  const res = await fetch(`${baseURL}/admin/users`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);

  return resjson;
}

export async function getUsersByID() {
  await checkAuth();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
  };

  const res = await fetch(`${baseURL}/admin/usersbyid`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}
export async function editUserInfo({ data }) {
  await checkAuth();
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    // body: JSON.stringify(data),
    body: JSON.stringify({
      firstName: data.firstName,
      lastName: data.lastName,
      companyName: data.companyName,
      companyLocation: data.companyLocation,
      gstin: data.gstin,
      website: data.website,
      bio: data.bio,
      isRegistrationComplete: true,
    }),
  };
  console.log(requestOptions);
  const res = await fetch(`${baseURL}/admin/usersbyid`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}
export async function editRegisterInfo({ data }) {
  await checkAuth();

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify({
      companyName: data.companyName ? data.companyName : undefined,
      companyLocation: data.companyLocation ? data.companyLocation : undefined,
      gstin: data.gstin ? data.gstin : undefined,
      website: data.website ? data.website : undefined,
      bio: data.bio ? data.bio : undefined,
      isRegistrationComplete: true,
    }),
  };
  console.log(requestOptions);
  const res = await fetch(
    `${baseURL}/admin/register-info-update`,
    requestOptions
  );
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}
export async function statusControllerUser({ user_id, isActive }) {
  await checkAuth();

  // const user = localStorage.getItem("user");
  console.log(user_id);
  console.log(isActive);
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify({
      user_id,
      isActive,
    }),
  };

  const res = await fetch(`${baseURL}/admin/users/editstatus`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}
