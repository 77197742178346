import React from "react";

const Pagination = ({ items, setPage, page }) => {
  console.log(items);
  if (items.totalPage <= 1) {
    return null;
  }
  return (
    <div className="flex align-items-center m-1 justify-content-center">
      <button
        className="btn mr-1"
        onClick={() => setPage((old) => Math.max(old - 1, 0))}
        disabled={page === 1 || page < 1}
      >
        Prev
      </button>
      {items && (
        <span>
          Current Page: {page}/{items.totalPage}
        </span>
      )}
      <button
        className="btn ml-1"
        onClick={() => {
          // if (!res0.isPreviousData && res0.data.next) {
          setPage((old) => old + 1);
          // }
        }}
        // Disable the Next Page button until we know a next page is available
        disabled={page === items.totalPage}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;
