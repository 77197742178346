import "./InfoWrapper.css";

const CardWrapperInput = ({ title, children, optional }) => (
  <div className="card-wrapper-input card-wrapper align-items-center">
    <div className="card-wrapper-item mb-1">
      <strong>{title}</strong>
      {optional === "true" && <span> (optional)</span>}
    </div>
    <div className="card-wrapper-item">{children}</div>
  </div>
);

export default CardWrapperInput;
