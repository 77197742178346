import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getPosts,
  statusControllerPost,
  getEmpPosts,
} from "queries/postsQueries";
import { Link } from "react-router-dom";
import Pagination from "helpers/Pagination";
import TableData from "./TableData";
import useUserStore from "stores/UserStore";
import "./ViewJobPosts.css";
import Spinner from "components/elements/Spinner";
const AdminUsers = () => {
  const [page, setPage] = React.useState(1);
  const role = useUserStore((state) => state.role);
  const user_id = useUserStore((state) => state.id);
  const queryClient = useQueryClient();
  const [isSegmentActive, setSegmentActive] = useState(0);
  const [isActive, setActive] = useState(true);

  const postsData = useQuery(
    ["posts", page, isActive],
    () =>
      role === "admin"
        ? getPosts(page, isActive)
        : getEmpPosts(page, user_id, isActive),
    {
      keepPreviousData: true,
    }
  );

  const editstatusMutation = useMutation(statusControllerPost, {
    onSuccess: () => {
      queryClient.invalidateQueries("posts");
    },
  });

  // const indexOfLastPost = currentPage * postsPerPage;
  // const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);
  const logging = (value) => {
    console.log(value);
  };
  // Change page
  // const paginate = (pageNumber) => setCurrentPage(pageNumber);
  // if (postsData.isLoading) {
  //   return <span>Loading...</span>;
  // }

  const segmentDataArray = ["Active Posts", "Inactive Posts"];
  // if (isError) {
  //     return <span>Error: {error.message}</span>;
  // }
  return (
    <React.Fragment>
      <div className="flex justify-space-between p-2">
        <h1 className="dashboard-title">Posts</h1>
        <Link to="/admin/newpost">
          <button className="submit-btn">Create New</button>
        </Link>
      </div>
      <div className="segmented-view flex pl-2 pb-2">
        {segmentDataArray.map((value, index) => (
          <div
            className={
              isSegmentActive === index
                ? "segmented-item active"
                : "segmented-item"
            }
            key={index}
            onClick={() => {
              setActive(index === 0 ? true : false);
              setSegmentActive(index);
            }}
          >
            <h2>{value}</h2>
          </div>
        ))}
      </div>
      <>
        {postsData.isLoading ? (
          <Spinner />
        ) : postsData.isError ? (
          <span>Error: {postsData.error.message}</span>
        ) : postsData.data && postsData.data.totalCount === 0 ? (
          <span className="m-auto">
            <strong>There is no post to display</strong>
          </span>
        ) : postsData.data !== "undefined" ? (
          <TableData
            postsData={postsData}
            setPage={setPage}
            page={page}
            editstatusMutation={editstatusMutation}
          />
        ) : (
          <p>No data recieved</p>
        )}
      </>
    </React.Fragment>
  );
};

export default AdminUsers;
