import { Fragment } from "react";
import Modal from "components/elements/ModalWrapper";
import InfoWrapper from "components/elements/InfoWrapperInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddSectionModal = (props) => {
  const {
    showModal,
    setShowModal,
    onSectionSubmit,
    register,
    handleSubmit,
    isEdit,
    setEdit,
    editData,
    setEditData,
    errors,
    modalRef,
  } = props;

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setEdit(false);
      setEditData("");
      setShowModal(true);
    }
  };
  return (
    <Fragment>
      <div className="modal-wrapper" ref={modalRef} onClick={closeModal}>
        <div className="modal-container flex-column justify-content-around align-items-center p-2">
          <h4>{isEdit ? "Edit" : "Add"} Section</h4>
          <div className="flex-column align-items-center justify-space-around">
            <form
              className="info-viewer justify-content-center flex-column mv-1"
              onSubmit={handleSubmit(onSectionSubmit)}
            >
              <InfoWrapper title="Section Title">
                <div className="wrap-input">
                  <input
                    className="input"
                    id="sectionTitle"
                    name="sectionTitle"
                    placeholder="Enter your Title"
                    defaultValue={isEdit ? editData.sectionTitle : ""}
                    ref={register({
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                  />
                </div>
                {errors.sectionTitle && (
                  <span className="is-danger">
                    {errors.sectionTitle.message}
                  </span>
                )}
              </InfoWrapper>
              <InfoWrapper title="Description">
                <div className="wrap-input">
                  <textarea
                    className="input"
                    id="sectionDescription"
                    name="sectionDescription"
                    placeholder="Description"
                    defaultValue={isEdit ? editData.sectionDescription : ""}
                    ref={register({
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                  />
                </div>
                {errors.sectionDescription && (
                  <span className="is-danger">
                    {errors.sectionDescription.message}
                  </span>
                )}
              </InfoWrapper>
              <button className="submit-btn inline-block m-0-auto">Save</button>
            </form>
            <div className="cancel-btn" onClick={() => setShowModal(true)}>
              Cancel
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddSectionModal;
