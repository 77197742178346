import React from "react";
import DashboardLayout from "layout/Dashboard";
import { Redirect, Route } from "react-router-dom";

import useAuthStore from "stores/AuthStore";
import useUserStore from "stores/UserStore";
import { checkAuth } from "hooks/checkAuth";

const AuthDashboardAdminRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const isRegistrationComplete = useUserStore(
    (state) => state.isRegistrationComplete
  );
  const role = useUserStore((state) => state.role);
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn && role === "admin" ? (
          <DashboardLayout>
            <Component {...props} />
          </DashboardLayout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default AuthDashboardAdminRoute;
