import { Fragment, useRef } from "react";
import { useForm } from "react-hook-form";
import CardWrapper from "components/elements/CardWrapperInput";
import { useQueryClient, useMutation } from "react-query";
import { addEducation } from "queries/userQueries";

const AddEducationModal = ({
  setShowModal,
  showModal,
  education,
  edit,
  setEdit,
  modalData,
  setModalData,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const modalRef = useRef();

  const queryClient = useQueryClient();
  const startYearArray = [];
  const endYearArray = [];
  for (var i = 2022; i > 1979; i--) {
    startYearArray.push(i);
  }
  for (var i = 2027; i > 1979; i--) {
    endYearArray.push(i);
  }
  const resetValues = () => {
    setModalData();
    setEdit(false);
    setShowModal(true);
  };
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      resetValues();
    }
  };
  const mutation = useMutation(addEducation, {
    onSuccess: () => {
      queryClient.invalidateQueries("userInfo");
      resetValues();
    },
    onError: () => {
      console.log("error");
    },
  });

  const onSubmit = async (data) => {
    try {
      if (edit === true) {
        const index = education.findIndex((ed) => ed._id === modalData._id);
        education[index].qualification = data.qualification;
        education[index].status = data.status;
        education[index].degree = data.degree;
        education[index].college = data.college;
        education[index].university = data.university;
        education[index].startYear = data.startYear;
        education[index].endYear = data.endYear;
        education[index].stream = data.stream;
        education[index].percentage = data.percentage;
      } else {
        education.push(data);
      }
      // education.push(data);
      await mutation.mutate(education);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Fragment>
      <div className="modal-wrapper" ref={modalRef} onClick={closeModal}>
        <div className="modal-container flex-column justify-content-around align-items-center p-2">
          <h2>Add Education</h2>
          <div className="flex-column align-items-center justify-space-around">
            <form
              className="info-viewer justify-content-center flex-column mv-1"
              onSubmit={handleSubmit(onSubmit)}
            >
              <CardWrapper title="Select Qualification">
                <div className="wrap-input">
                  <select
                    name="qualification"
                    defaultValue={
                      edit === true
                        ? modalData && modalData.qualification
                        : null
                    }
                    className="mv-0 classic input"
                    ref={register({
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                  >
                    <option value="">Select Qualification</option>
                    <option value="undergraduation">Under Graduation</option>
                    <option value="postgraduation">Post Graduation</option>
                    <option value="pgdiploma">PG Diploma </option>
                    <option value="phD">phD</option>
                    <option value="diploma">Diploma</option>
                  </select>
                </div>
                {errors.qualification && (
                  <span className="is-danger">
                    {errors.qualification.message}
                  </span>
                )}
              </CardWrapper>

              <CardWrapper title="Course Status">
                <input
                  className="mv-1"
                  type="radio"
                  id="completed"
                  name="status"
                  checked={
                    modalData && modalData.status === "completed"
                      ? "true"
                      : "false"
                  }
                  value="Completed"
                  ref={register({
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                />
                <label className="ml-0 mv-1" htmlFor="completed">
                  Completed
                </label>
                <input
                  className="ml-1 mv-1"
                  type="radio"
                  id="pursuing"
                  checked={
                    modalData && modalData.status === "pursuing"
                      ? "true"
                      : "false"
                  }
                  name="status"
                  value="pursuing"
                  ref={register({
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                />
                <label className="ml-0 mv-1" htmlFor="pursuing">
                  Pursuing
                </label>
                {errors.status && (
                  <span className="is-danger">{errors.status.message}</span>
                )}
              </CardWrapper>

              <CardWrapper title="Degree">
                <div className="wrap-input">
                  <input
                    className="input"
                    id="degree"
                    name="degree"
                    defaultValue={modalData && modalData.degree}
                    placeholder="Enter your Degree"
                    ref={register({
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                  />
                </div>
                {errors.degree && (
                  <span className="is-danger">{errors.degree.message}</span>
                )}
              </CardWrapper>
              <CardWrapper title="College">
                <div className="wrap-input">
                  <input
                    className="input"
                    id="college"
                    name="college"
                    defaultValue={modalData && modalData.college}
                    placeholder="Enter your College"
                    ref={register({
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                  />
                </div>
                {errors.college && (
                  <span className="is-danger">{errors.college.message}</span>
                )}
              </CardWrapper>
              <CardWrapper title="University">
                <div className="wrap-input">
                  <input
                    className="input"
                    id="university"
                    name="university"
                    defaultValue={modalData && modalData.university}
                    placeholder="University"
                    ref={register({
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                  />
                </div>
                {errors.university && (
                  <span className="is-danger">{errors.university.message}</span>
                )}
              </CardWrapper>
              <div className="flex justify-space-between">
                <CardWrapper title="Start Year">
                  <div className="wrap-input">
                    <select
                      className="classic input-half"
                      name="startYear"
                      id="startYear"
                      defaultValue={
                        edit === true ? modalData && modalData.startYear : null
                      }
                      ref={register({
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      })}
                    >
                      <option value="">Select Year</option>
                      {startYearArray.map((year) => (
                        <option value={year} key={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors.startYear && (
                    <span className="is-danger">
                      {errors.startYear.message}
                    </span>
                  )}
                </CardWrapper>

                <CardWrapper title="End Year">
                  <div className="wrap-input">
                    <select
                      className="classic input-half"
                      name="endYear"
                      id="endYear"
                      defaultValue={
                        edit === true ? modalData && modalData.endYear : null
                      }
                      ref={register({
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      })}
                    >
                      <option value="">Select Year</option>
                      {endYearArray.map((year) => (
                        <option value={year} key={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors.endYear && (
                    <span className="is-danger">{errors.endYear.message}</span>
                  )}
                </CardWrapper>
              </div>
              <CardWrapper title="Stream" optional="true">
                <div className="wrap-input">
                  <input
                    className="input"
                    id="stream"
                    name="stream"
                    defaultValue={modalData && modalData.stream}
                    placeholder="Stream"
                    ref={register()}
                  />
                </div>
                {errors.stream && (
                  <span className="is-danger">{errors.stream.message}</span>
                )}
              </CardWrapper>
              <CardWrapper title="Percentage" optional="true">
                <div className="wrap-input">
                  <input
                    className="input"
                    type="Number"
                    id="percentage"
                    name="percentage"
                    defaultValue={modalData && modalData.percentage}
                    placeholder="0.00"
                    ref={register()}
                  />
                </div>
                {errors.percentage && (
                  <span className="is-danger">{errors.percentage.message}</span>
                )}
              </CardWrapper>

              <button className="submit-btn inline-block m-0-auto">Save</button>
            </form>
            <button
              className="cancel-btn"
              onClick={() => {
                resetValues();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddEducationModal;
