import create from "zustand";
// import { mountStoreDevtool } from "simple-zustand-devtools";
const useUserStore = create(() => ({
  id: localStorage.getItem("user_id"),
  name: localStorage.getItem("name"),
  role: localStorage.getItem("userRole"),
  appliedPosts: localStorage.getItem("appliedPosts"),
  isRegistrationComplete: localStorage.getItem("isRegistrationComplete"),
}));
// if (process.env.NODE_ENV === "development") {
//     mountStoreDevtool("UserStore", useUserStore);
// }
export default useUserStore;
