import { useState } from "react";
import ReactPlayer from "react-player";
import InfoWrapperInput from "components/elements/InfoWrapperInput";
import { useForm, useFieldArray } from "react-hook-form";
import "views/dashboard/course/CourseEdit.css";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getCategory } from "queries/categoryQueries";
import {
  getCourseById,
  editCourseDetails,
  addLectureURL,
  addLectureQuizQuestions,
  lectureQuizQuestionsOperations,
} from "queries/courseQueries";
import Spinner from "components/elements/Spinner";
import { useEffect } from "react";

const EditQuizQuestions = (props) => {
  const { onSubmit, item, resetToDefaults } = props;
  const { register, handleSubmit, errors, watch, control, reset, setValue } =
    useForm({
      defaultValues: {
        answerOptions: item.answerOptions || [],
      },
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "answerOptions",
  });
  return (
    <div className="bg-lb">
      <form className="info-viewer mt-2" onSubmit={handleSubmit(onSubmit)}>
        <h4 className="mt-1">Edit Question</h4>
        <InfoWrapperInput title="Question">
          <div className="wrap-input">
            <input
              className="input "
              id="question"
              name="question"
              placeholder="Enter your Question"
              defaultValue={item.question}
              ref={register({
                required: {
                  value: true,
                  message: "This field is required",
                },
              })}
            />
          </div>
          {errors.question && (
            <span className="is-danger">{errors.question.message}</span>
          )}
        </InfoWrapperInput>
        <InfoWrapperInput title="Answers">
          <div className="wrap-input flex-start">
            {fields.map(({ id, answerText, isCorrect }, index) => {
              return (
                <div className="flex" key={id}>
                  <input
                    className="input mb-0 flex-grow"
                    id="answerOptions"
                    name={`answerOptions[${index}].answerText`}
                    defaultValue={answerText}
                    placeholder="Type your option here"
                    ref={register()}
                  />
                  <input
                    type="checkbox"
                    id="answerOptions"
                    name={`answerOptions[${index}].isCorrect`}
                    defaultValue={isCorrect}
                    className="m-0"
                    ref={register()}
                    // onClick={() => remove(index)}
                  />

                  <span
                    type="button"
                    className="m-0"
                    onClick={() => remove(index)}
                  >
                    <i className="fas fa-times"></i>
                  </span>
                </div>
              );
            })}
            <div className="">
              <button
                type="button"
                className="submit-btn ml-00 mr-auto mt-1"
                onClick={() => {
                  append({ answerText: "", isCorrect: false });
                }}
              >
                + Add An Option
              </button>
            </div>
          </div>
          {errors.learn && (
            <span className="is-danger">{errors.learn.message}</span>
          )}
        </InfoWrapperInput>
        <div className="flex justify-content-end">
          <button
            className="cancel-btn m-0 p-1"
            type="button"
            // disabled={disableBtn}
            onClick={resetToDefaults}
          >
            Cancel
          </button>
          <button
            className="submit-btn m-0 p-1"
            type="submit"
            // disabled={disableBtn}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

const QuizContent = (props) => {
  const { lecture } = props;

  const [isEdit, setEdit] = useState(false);
  const [editInformation, setEditInformation] = useState("");
  const [answerOptionsDefaults, setAnswerOptionsDefaults] = useState([]);
  const [isQuestionVisible, setQuestionVisible] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const queryClient = useQueryClient();
  const { register, handleSubmit, errors, watch, control, reset, setValue } =
    useForm({
      defaultValues: {
        answerOptions: answerOptionsDefaults,
      },
    });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "answerOptions",
  });

  //   useEffect(() => {
  //     console.log("use effect of answer options default");
  //     console.log(answerOptionsDefaults);
  //     setValue("answerOptions", answerOptionsDefaults);
  //   }, [answerOptionsDefaults]);
  const onDeleteClick = (item) => {
    console.log(item);
    const newArray = [...lecture.quizQuestions];
    const index = newArray.findIndex((val) => val._id === item._id);
    newArray.splice(index, 1);
    mutation.mutate({ data: newArray, id: lecture._id });
  };
  const resetToDefaults = () => {
    setEdit(false);
    setEditInformation("");
    setAnswerOptionsDefaults([]);
    setQuestionVisible(false);
  };
  const mutation = useMutation(lectureQuizQuestionsOperations, {
    onSuccess: () => {
      queryClient.invalidateQueries("curriculum");
      setDisableBtn(false);
      resetToDefaults();
    },
    onError: () => {
      setDisableBtn(false);
    },
  });
  const onSubmit = async (data) => {
    // setDisableBtn(true);
    console.log("Quiz Submit");
    console.log(data);
    const newArray = [...lecture.quizQuestions];
    console.log("new array is ");
    console.log(newArray);
    console.log(editInformation);
    if (editInformation !== "") {
      newArray.map((item) => {
        if (item._id === editInformation) {
          item.question = data.question;
          item.answerOptions = data.answerOptions;
        }
      });
    } else {
      newArray.push(data);
      console.log("Pushed data to new array");
      console.log(newArray);
    }

    try {
      console.log("add or edit Lecture Url ");
      console.log(data);
      const id = lecture._id;
      await mutation.mutate({ data: newArray, id });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="lecture-content">
      {lecture.quizQuestions.map((item) => (
        <div className="mt-1 bg-lb p-1" key={item._id}>
          {isEdit && item._id === editInformation ? (
            <EditQuizQuestions
              onSubmit={onSubmit}
              item={item}
              resetToDefaults={resetToDefaults}
            />
          ) : (
            <div className="flex justify-space-between mt-1">
              <div className="">{item.question}</div>
              <div className="hidden">
                <button
                  type="button"
                  className="btn font-btn"
                  onClick={() => {
                    console.log("item description");
                    console.log(item.answerOptions);
                    setEditInformation(item._id);
                    setAnswerOptionsDefaults(item.answerOptions);

                    setQuestionVisible(false);
                    setEdit(true);
                    console.log("anser options defaults is:");

                    console.log(answerOptionsDefaults);
                  }}
                >
                  <i className="far fa-edit"></i>
                </button>

                <button
                  type="button"
                  className="btn font-btn"
                  onClick={() => onDeleteClick(item)}
                >
                  <i className="far fa-trash-alt"></i>
                </button>
              </div>
            </div>
          )}
        </div>
      ))}
      {isQuestionVisible ? (
        <div className="bg-lb">
          <form className="info-viewer mt-2" onSubmit={handleSubmit(onSubmit)}>
            <h4 className="mt-1">Add New Question</h4>
            <InfoWrapperInput title="Question">
              <div className="wrap-input">
                <input
                  className="input "
                  id="question"
                  name="question"
                  placeholder="Enter your Question"
                  ref={register({
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                />
              </div>
              {errors.question && (
                <span className="is-danger">{errors.question.message}</span>
              )}
            </InfoWrapperInput>
            <InfoWrapperInput title="Answers">
              <div className="wrap-input flex-start">
                {fields.map((field, index) => {
                  return (
                    <div className="flex" key={field.id}>
                      <input
                        className="input mb-0  flex-grow"
                        id="answerOptions"
                        name={`answerOptions[${index}].answerText`}
                        placeholder="Type your option here"
                        ref={register()}
                      />
                      <input
                        type="checkbox"
                        id="answerOptions"
                        name={`answerOptions[${index}].isCorrect`}
                        className="m-0"
                        ref={register()}
                        // onClick={() => remove(index)}
                      />

                      <span
                        type="button"
                        className="m-0"
                        onClick={() => remove(index)}
                      >
                        <i className="fas fa-times"></i>
                      </span>
                    </div>
                  );
                })}
                <div className="">
                  <button
                    type="button"
                    className="submit-btn ml-00 mr-auto mt-1"
                    onClick={() => {
                      append({ answerText: "", isCorrect: false });
                    }}
                  >
                    + Add An Option
                  </button>
                </div>
              </div>
              {errors.learn && (
                <span className="is-danger">{errors.learn.message}</span>
              )}
            </InfoWrapperInput>
            <div className="flex justify-content-end">
              <button
                className="cancel-btn m-0 p-1"
                type="button"
                // disabled={disableBtn}
                onClick={resetToDefaults}
              >
                Cancel
              </button>
              <button
                className="submit-btn m-0 p-1"
                type="submit"
                // disabled={disableBtn}
              >
                Save
              </button>
            </div>
          </form>
        </div>
      ) : (
        <button
          className="submit-btn m-0 p-1"
          type="button"
          // disabled={disableBtn}
          onClick={() => {
            setEdit(false);
            setQuestionVisible(true);
          }}
        >
          Add Question
        </button>
      )}
    </div>
  );
};

export default QuizContent;
