import "./Sidebar.css";
import { NavLink } from "react-router-dom";

import useUserStore from "stores/UserStore";

const Sidebar = () => {
  const role = useUserStore((state) => state.role);
  console.log(role);
  return (
    <div className="sidebar">
      <ul>
        {role === "employer" ? (
          <>
            {/* <li className=""> */}
            {/*   <NavLink exact className="nav-link" to="/admin/"> */}
            {/*     Dashboard */}
            {/*   </NavLink> */}
            {/* </li> */}
            {/* <li className="">
                    <NavLink exact className="nav-link" to="/admin/profile">
                        Profile
                    </NavLink>
                </li> */}
            <li className="">
              <NavLink exact className="nav-link" to="/admin/posts">
                Post
              </NavLink>
            </li>
          </>
        ) : role === "admin" ? (
          <>
            {/* <li className=""> */}
            {/*   <NavLink exact className="nav-link" to="/admin/"> */}
            {/*     Dashboard */}
            {/*   </NavLink> */}
            {/* </li> */}
            {/* <li className="">
                    <NavLink exact className="nav-link" to="/admin/profile">
                        Profile
                    </NavLink>
                </li> */}
            <li className="">
              <NavLink exact className="nav-link" to="/admin/users">
                Users
              </NavLink>
            </li>
            <li className="">
              <NavLink exact className="nav-link" to="/admin/courses">
                Courses
              </NavLink>
            </li>
            <li className="">
              <NavLink exact className="nav-link" to="/admin/posts">
                Post
              </NavLink>
            </li>
            <li className="">
              <NavLink exact className="nav-link" to="/admin/categories">
                Categories
              </NavLink>
            </li>
          </>
        ) : null}
      </ul>
    </div>
  );
};

export default Sidebar;
