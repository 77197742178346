import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  getCategory,
  postCreateCategory,
  editCategory,
  deleteCategory,
} from "queries/categoryQueries";
import { useForm } from "react-hook-form";
import { successToast, errorToast } from "helpers/Toast";
import Pagination from "helpers/Pagination";

const Category = () => {
  const [page, setPage] = React.useState(1);

  const queryClient = useQueryClient();

  const [hidden, setHidden] = useState(true);

  const successfullyUdated = (value) => {
    if (value === "create") return successToast("Successfullly Added Category");
    if (value === "edited")
      return successToast("Successfullly Edited Category");
    return successToast("Deleted Category");
  };

  const errorToastMessage = () => {
    errorToast("Error: Information was not updated");
  };

  const mutation = useMutation(postCreateCategory, {
    onSuccess: () => {
      setHidden(true);
      successfullyUdated("create");
      queryClient.invalidateQueries("categories");
    },
    onError: () => {
      errorToastMessage();
    },
  });
  const editMutation = useMutation(editCategory, {
    onSuccess: () => {
      setHidden(true);
      successfullyUdated("edited");
      queryClient.invalidateQueries("categories");
    },
  });

  const deleteMutation = useMutation(deleteCategory, {
    onSuccess: () => {
      setHidden(true);
      successfullyUdated();
      queryClient.invalidateQueries("categories");
    },
  });

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = async (data) => {
    try {
      console.log(data);
      await mutation.mutate({ data });
    } catch (error) {
      console.log(error);
    }
  };

  const { isLoading, isError, data, error } = useQuery(
    ["categories", page],
    () => getCategory(page),
    {
      keepPreviousData: true,
    }
  );

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }
  return (
    <>
      <div className="category">
        <div className="flex justify-space-between p-2">
          <h1 className="dashboard-title">Categories</h1>
          {hidden ? (
            <button className="submit-btn" onClick={() => setHidden(false)}>
              Create New
            </button>
          ) : (
            <div>
              <form className="flex-column" onSubmit={handleSubmit(onSubmit)}>
                <input
                  className="input "
                  placeholder="Enter new category"
                  id="title"
                  name="title"
                  ref={register({
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                />
                {errors.title && (
                  <span className="is-danger">{errors.title.message}</span>
                )}
                <div className="mt-0">
                  <button className="submit-btn ml-0" type="submit">
                    Save
                  </button>
                  <button
                    className="cancel-btn ml-0"
                    onClick={() => setHidden(true)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
        {data.totalCount === 0 ? (
          <span className="m-auto">
            <strong>There is no Categeries to display </strong>
          </span>
        ) : (
          <div className="dashboard-table">
            <table className="table table-bordere">
              <thead>
                <tr>
                  {/* <th scope="col">UID</th> */}
                  <th scope="col">Title</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              {data.results.map((category) => (
                <tbody key={category._id}>
                  <tr>
                    <td className="capitalize">{category.title}</td>
                    <td>
                      {/* <button className="submit-btn mr-0">
                                            Edit
                                        </button> */}
                      <button
                        className="cancel-btn"
                        onClick={() => {
                          deleteMutation.mutate({
                            title: category.title,
                          });
                        }}
                      >
                        Del
                      </button>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        )}
        <Pagination items={data} setPage={setPage} page={page} />
      </div>
    </>
  );
};

export default Category;
