import { Link, Redirect } from "react-router-dom";
import "./Login.css";
import Card from "components/Card";
import "helpers/fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { login } from "queries/authQueries";
import { saveToken, saveUser } from "helpers/storageHelper";

import { successToast, errorToast } from "helpers/Toast";
import { useMutation, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import useErrorStore from "stores/ErrorStore";
import useDataStore from "stores/DataStore";

const Login = () => {
  const queryClient = useQueryClient();
  const redirectData = useDataStore((state) => state.redirectData);
  const removeRedirectData = useDataStore((state) => state.removeRedirectData);
  const message = useErrorStore((state) => state.message);
  const mutation = useMutation(login, {
    onSuccess: (data) => {
      saveToken(data.accessToken, data.refreshToken);
      saveUser(data.user);
    },
  });
  const { register, handleSubmit, errors } = useForm();

  if (redirectData == "success") {
    console.log("success");
    successToast("Successfully Registered user");
    removeRedirectData();
  }

  const onSubmit = async (data) => {
    try {
      await mutation.mutate({ data });
    } catch (error) {
      console.log("error");
    }
  };

  // if (isSuccess) {
  //     return <Redirect to="/" />;
  // } else {
  return (
    <Card name="Login">
      {mutation.isLoading ? (
        "Loading"
      ) : (
        <>
          {mutation.isError ? (
            <div className="is-danger">An error occurred: {message}</div>
          ) : null}
          {mutation.isSuccess ? <div>Logged in</div> : null}
          <form className="validate-form" onSubmit={handleSubmit(onSubmit)}>
            {/* {isLoading ? <div>Loading.......</div> : null} */}
            <div className="wrap-input">
              <label className="mb-0">Email Id</label>
              <input
                className="input"
                id="email"
                name="email"
                placeholder="Enter your email"
                // defaultValue="pshishir99@gmail.com"
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Enter a valid email",
                  },
                })}
              />
              <span className="symbol-input">
                {/* <i className="fa fa-envelope" aria-hidden="true"></i> */}
                {/* <FontAwesomeIcon icon="envelope" /> */}
              </span>
            </div>
            {errors.email && (
              <span className="is-danger">{errors.email.message}</span>
            )}

            <div className="wrap-input">
              <label className="mb-0">Password</label>
              <input
                className="input"
                id="password"
                type="password"
                name="password"
                placeholder="Enter your password"
                // defaultValue=";,.pyfgc"
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                  minLength: {
                    value: 8,
                    message: "Minimum Length is 8",
                  },
                })}
              />
              {/* <span className="symbol-input">
                                <i
                                    className="fa fa-lock"
                                    aria-hidden="true"
                                ></i>
                            </span> */}
            </div>
            {errors.password && (
              <span className="is-danger">{errors.password.message}</span>
            )}

            {/* {isError ? ( */}
            {/* <div className="is-danger">
                         {error.message},{message}
                    </div> */}
            {/* ) : null} */}
            <div className="flex-column align-items-center">
              <button className="button-blue" type="submit">
                Login
              </button>
              <div className="forgot-password mb-0">
                <span className="txt1">Forgot </span>
                <Link className="txt2 forgot-password-text-color" to="/forgot">
                  Password?
                </Link>
              </div>

              <div className="text-center p-t-136">
                <Link
                  className="txt2 forgot-password-text-color"
                  to="/registration/student"
                >
                  Create your Account
                  <i
                    className="fa fa-long-arrow-right m-l-5"
                    aria-hidden="true"
                  ></i>
                </Link>
              </div>
            </div>
          </form>
        </>
      )}
    </Card>
  );
  // }
};

export default Login;
