import create from "zustand";

const useAuthStore = create((set) => ({
  isLoggedIn: localStorage.getItem("authState") || false,
  // isRegistrationComplete:
  //   localStorage.getItem("isRegistrationComplete") || false,
  setLogInState: () => set({ isLoggedIn: true }),
  // setLogOutState: () =>
  //   set({ isLoggedIn: false, isRegistrationComplete: false }),
  setLogOutState: () => set({ isLoggedIn: false }),
  setisRegistrationCompleteState: () => set({ isRegistrationComplete: true }),
}));

export default useAuthStore;
