import { Link, Redirect } from "react-router-dom";

import Card from "components/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";

const ForgotPassword = () => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = async (data) => {
    // try {
    //     await mutate({ data });
    // } catch (error) {
    console.log("error");
    // }
  };
  return (
    <Card name="Forgot Password?">
      <form
        className="login-form validate-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* {isLoading ? <div>Loading.......</div> : null} */}
        <div className="wrap-input">
          <input
            className="input"
            id="email"
            name="email"
            placeholder="Enter your email"
            ref={register({
              required: {
                value: true,
                message: "This field is required",
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Enter a valid email",
              },
            })}
          />
        </div>
        {errors.email && (
          <span className="is-danger">{errors.email.message}</span>
        )}

        <button className="button-blue" type="submit">
          Submit
        </button>
        <div className="text-center p-t-136">
          <Link className="txt2" to="/login">
            Back to Login?
            <i className="fa fa-long-arrow-right m-l-5" aria-hidden="true"></i>
          </Link>
        </div>
      </form>
    </Card>
  );
};

export default ForgotPassword;
