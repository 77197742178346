import React from "react";
import MainLayout from "layout/MainLayout";

import { Redirect, Route } from "react-router-dom";

import useAuthStore from "stores/AuthStore";
import useUserStore from "stores/UserStore";

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  // const isLoggedIn = checkAuth();
  // console.log(isLoggedIn);
  // console.log("isLoggedIn");
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const isRegistrationComplete = useUserStore(
    (state) => state.isRegistrationComplete
  );
  const role = useUserStore((state) => state.role);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <MainLayout>
            <Component {...props} />
          </MainLayout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default AuthenticatedRoute;
