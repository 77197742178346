import {
  createNewLecture,
  createNewSection,
  deleteSection,
  editSection,
  sortSection,
  getCurriculum,
  editLecture,
  deleteLecture,
  sortLecture,
} from "queries/courseQueries";
import { useMutation, useQuery, useQueryClient } from "react-query";

const useCourseCurriculumQueries = (
  course_id,
  setEdit,
  setEditData,
  setSectionIsHidden,
  setLectureIsHidden,
  setSortIsHidden,
  setLessonSortIsHidden,
  setSortLessonValue,
  temperaryCurriculumData,
  setTemperaryCurriculumData
) => {
  const queryClient = useQueryClient();

  const {
    isLoading,
    isError,
    data: curriculumData,
    error,
  } = useQuery(["curriculum", course_id], () => getCurriculum(course_id));

  const sectionMutation = useMutation(createNewSection, {
    onSuccess: (data) => {
      console.log("Create Section Mutation function successfully completed");
      console.log(data);

      const newCurriculumData = { ...curriculumData };
      newCurriculumData.sections.push(data);

      console.log("New Curriculum Data is:");
      console.log(newCurriculumData);

      queryClient.setQueryData(
        ["curriculum", curriculumData.course_id],
        newCurriculumData
      );

      // The below statement is not required as the query data is stored in the above statement
      queryClient.invalidateQueries("curriculum");

      setSectionIsHidden(true);
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const editSectionMutation = useMutation(editSection, {
    onSuccess: (data) => {
      console.log("Edit Section Mutation function successfully completed");
      console.log(data);

      const newCurriculumData = { ...curriculumData };
      newCurriculumData.sections.map((element) => {
        if (element._id === data._id) {
          element.sectionTitle = data.sectionTitle;
          element.sectionDescription = data.sectionDescription;
        }
      });

      console.log("New Curriculum Data is:");
      console.log(newCurriculumData);

      queryClient.setQueryData(
        ["curriculum", curriculumData.course_id],
        newCurriculumData
      );

      setEdit(false);
      setEditData("");

      // The below statement is not required as the query data is stored in the above statement
      queryClient.invalidateQueries("curriculum");

      setSectionIsHidden(true);
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const deleteSectionMutation = useMutation(deleteSection, {
    onSuccess: (data) => {
      console.log("Delete Section Mutation function successfully completed");
      console.log(data);

      const newData = [...curriculumData.sections];
      newData.map((element) => {
        if (element._id === data._id) {
          return null;
        }
      });

      // queryClient.setQueryData(["curriculum", curriculumData.course_id], data);

      // The below statement is not required as the query data is stored in the above statement
      queryClient.invalidateQueries("curriculum");
      setSectionIsHidden(true);
    },
    onError: (error) => {
      console.log(error);
    },
  });
  const sortSectionMutation = useMutation(sortSection, {
    onSuccess: async () => {
      await queryClient.invalidateQueries("curriculum");
      setTemperaryCurriculumData("");
      console.log("Successfully sorted sections");
      setSortIsHidden(true);
    },
  });
  const addLectureMutation = useMutation(createNewLecture, {
    onSuccess: async (data) => {
      console.log("Create Lecture Mutation function successfully completed");
      console.log(data);
      const newCurriculumData = { ...curriculumData };

      /* newCurriculumData.sections.map((element) => {
	*   if (element._id === temperaryData._id) {
	*     element.lectures.push(data);
	*   }
	* });
	* console.log("new curriculum data");
	
	* queryClient.setQueryData(
	*   ["curriculum", curriculumData.course_id],
	*   newCurriculumData
	* ); */

      // The below statement is not required as the query data is stored in the above statement
      await queryClient.invalidateQueries("curriculum");
      setLectureIsHidden(true);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const editLectureMutation = useMutation(editLecture, {
    onSuccess: (data) => {
      console.log("Edit Section Mutation function successfully completed");
      console.log(data);
      /*
       *       const newCurriculumData = { ...curriculumData };
       *       newCurriculumData.sections.map((element) => {
       *         if (element._id === data._id) {
       *           element.sectionTitle = data.sectionTitle;
       *           element.sectionDescription = data.sectionDescription;
       *         }
       *       });
       *
       *       console.log("New Curriculum Data is:");
       *       console.log(newCurriculumData);
       *
       *       queryClient.setQueryData(
       *         ["curriculum", curriculumData.course_id],
       *         newCurriculumData
       *       ); */

      setEdit(false);
      setEditData("");

      // The below statement is not required as the query data is stored in the above statement
      queryClient.invalidateQueries("curriculum");

      setLectureIsHidden(true);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const sortLectureMutation = useMutation(sortLecture, {
    onSuccess: async () => {
      await queryClient.invalidateQueries("curriculum");
      setLessonSortIsHidden(true);
      setSortLessonValue("");
      setTemperaryCurriculumData("");
      console.log("Successfully sorted sections");
    },
  });

  const deleteLectureMutation = useMutation(deleteLecture, {
    onSuccess: (data) => {
      console.log("Delete Lecture Mutation function successfully completed");
      console.log(data);

      // queryClient.setQueryData(["curriculum", curriculumData.course_id], data);

      // The below statement is not required as the query data is stored in the above statement
      queryClient.invalidateQueries("curriculum");
      setSectionIsHidden(true);
    },
    onError: (error) => {
      console.log(
        "There has been an error and the lecture has not been deleted"
      );
      console.log(error);
    },
  });

  return {
    queryClient,
    isLoading,
    isError,
    error,
    curriculumData,
    sectionMutation,
    editSectionMutation,
    sortSectionMutation,
    deleteSectionMutation,
    addLectureMutation,
    editLectureMutation,
    sortLectureMutation,
    deleteLectureMutation,
  };
};

export default useCourseCurriculumQueries;
