import { Fragment } from "react";
import InfoWrapper from "components/elements/InfoWrapperInput";

const AddLectureModal = (props) => {
  const {
    setShowModal,
    showModal,
    onLectureSubmit,
    register,
    handleSubmit,
    errors,
    modalRef,
    isEdit,
    setEdit,
    editData,
    setEditData,
    temperaryData,
    setTemperaryData,
  } = props;
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setEdit(false);
      setEditData("");
      setTemperaryData("");
      setShowModal(true);
    }
  };

  return (
    <Fragment>
      <div className="modal-wrapper" ref={modalRef} onClick={closeModal}>
        <div className="modal-container flex-column justify-content-around align-items-center p-2">
          <h4>
            {isEdit ? "Edit" : "Add"}{" "}
            {temperaryData.type === "lecture" ? "Lecture" : "Quiz"}
          </h4>
          <div className="flex-column align-items-center justify-space-around">
            <form
              className="info-viewer justify-content-center flex-column mv-1"
              onSubmit={handleSubmit(onLectureSubmit)}
            >
              <InfoWrapper
                title={
                  temperaryData.type === "lecture"
                    ? "Lecture Title"
                    : "Quiz Title"
                }
              >
                <div className="wrap-input">
                  <input
                    className="input"
                    id="lectureTitle"
                    name="lectureTitle"
                    placeholder="Enter your Title"
                    defaultValue={isEdit ? editData.lectureTitle : ""}
                    ref={register({
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                  />
                </div>
                {errors.lectureTitle && (
                  <span className="is-danger">
                    {errors.lectureTitle.message}
                  </span>
                )}
              </InfoWrapper>
              <InfoWrapper title="Description">
                <div className="wrap-input">
                  <textarea
                    className="textarea"
                    id="lectureDescription"
                    name="lectureDescription"
                    placeholder="Description"
                    defaultValue={isEdit ? editData.lectureDescription : ""}
                    ref={register({
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                  />
                </div>
                {errors.lectureDescription && (
                  <span className="is-danger">
                    {errors.lectureDescription.message}
                  </span>
                )}
              </InfoWrapper>

              <button className="submit-btn m-0-auto">Save</button>
            </form>
            <div
              className="cancel-btn"
              onClick={() => {
                setShowModal(true);
              }}
            >
              Cancel
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddLectureModal;
