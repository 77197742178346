import { checkAuth } from "hooks/checkAuth";
import { baseURL } from "utils/constants";
import { setErrorData } from "helpers/queryHelpers";

export async function addSecondaryEducation({ data }) {
  await checkAuth();

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify({
      completedYear: data.year,
      school: data.school,
      board: data.board,
      percentage: data.percentage,
    }),
  };
  console.log("add secondary education");
  const res = await fetch(`${baseURL}/user/secondaryeducation`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function deleteSecondaryEducation() {
  await checkAuth();
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
  };
  const res = await fetch(`${baseURL}/user/secondaryeducation`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function addHigherSecondaryEducation({ data }) {
  await checkAuth();

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify({
      completedYear: data.year,
      college: data.college,
      board: data.board,
      percentage: data.percentage,
    }),
  };
  const res = await fetch(
    `${baseURL}/user/highersecondaryeducation`,
    requestOptions
  );
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function deleteHigherSecondaryEducation() {
  await checkAuth();

  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
  };
  const res = await fetch(
    `${baseURL}/user/highersecondaryeducation`,
    requestOptions
  );
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function addSkills(skills) {
  await checkAuth();

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify({
      skills,
    }),
  };
  const res = await fetch(`${baseURL}/user/skills`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function addEducation(education) {
  await checkAuth();

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify({
      education,
    }),
  };
  const res = await fetch(`${baseURL}/user/education`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function addCertificates(certificates) {
  await checkAuth();

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify({
      certificates,
    }),
  };
  const res = await fetch(`${baseURL}/user/certificates`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function deleteSkills(skills) {
  await checkAuth();

  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify({
      skills,
    }),
  };
  const res = await fetch(`${baseURL}/user/skills`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function deleteEducation(education) {
  await checkAuth();

  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify({
      education,
    }),
  };
  const res = await fetch(`${baseURL}/user/education`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function deleteCertificates(certificates) {
  await checkAuth();

  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify({
      certificates,
    }),
  };
  const res = await fetch(`${baseURL}/user/certificates`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}
