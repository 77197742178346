import { Fragment, useRef } from "react";
import { useForm } from "react-hook-form";
import Modal from "components/elements/ModalWrapper";
import CardWrapper from "components/elements/CardWrapperInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { addHigherSecondaryEducation } from "queries/userQueries";
import { errorToast } from "helpers/Toast";

const AddHigherSecondaryModal = ({
  setShowModal,
  showModal,
  edit,
  setEdit,
  modalData,
  setModalData,
  callback,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const modalRef = useRef();

  const yearArray = [];
  for (var i = 2022; i > 1979; i--) {
    yearArray.push(i);
  }
  const queryClient = useQueryClient();

  const resetValues = () => {
    setModalData();
    setEdit(false);
    setShowModal(true);
  };

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      resetValues();
    }
  };

  const mutation = useMutation(addHigherSecondaryEducation, {
    onSuccess: () => {
      queryClient.invalidateQueries("userInfo");
      callback();
      resetValues();
    },
    onError: () => {
      console.log("error");
      errorToast("Error: Information was not updated");
    },
  });

  const onSubmit = async (data) => {
    try {
      console.log(data);
      await mutation.mutate({ data });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Fragment>
      <div className="modal-wrapper" ref={modalRef} onClick={closeModal}>
        <div className="modal-container flex-column justify-content-around align-items-center p-2">
          <h2>Add Higher Secondary Education</h2>
          <div className="flex-column align-items-center justify-space-around">
            <form
              className="info-viewer justify-content-center flex-column mv-1"
              onSubmit={handleSubmit(onSubmit)}
            >
              <CardWrapper title="College">
                <div className="wrap-input">
                  <input
                    className="input"
                    id="college"
                    name="college"
                    defaultValue={modalData && modalData.college}
                    placeholder="Enter your College"
                    ref={register({
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                  />
                </div>
                {errors.college && (
                  <span className="is-danger">{errors.college.message}</span>
                )}
              </CardWrapper>
              <CardWrapper title="Board">
                <div className="wrap-input">
                  <input
                    className="input"
                    id="board"
                    name="board"
                    defaultValue={modalData && modalData.board}
                    placeholder="Enter your Board"
                    ref={register({
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                  />
                </div>
                {errors.board && (
                  <span className="is-danger">{errors.board.message}</span>
                )}
              </CardWrapper>
              <CardWrapper title="Year of Completion">
                <div className="wrap-input">
                  <select
                    className="classic input"
                    name="year"
                    id="year"
                    defaultValue={
                      edit === true ? modalData && modalData.year : null
                    }
                    ref={register({
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                  >
                    <option value="">Select Year</option>
                    {yearArray.map((year) => (
                      <option value={year} key={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.year && (
                  <span className="is-danger">{errors.year.message}</span>
                )}
              </CardWrapper>

              <CardWrapper title="Percentage">
                <div className="wrap-input">
                  <input
                    className="input"
                    type="number"
                    id="percentage"
                    name="percentage"
                    defaultValue={modalData && modalData.percentage}
                    placeholder="0.00"
                    ref={register({
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                  />
                </div>
                {errors.percentage && (
                  <span className="is-danger">{errors.percentage.message}</span>
                )}
              </CardWrapper>

              <button className="submit-btn m-0-auto">Save</button>
            </form>
            <button
              className="cancel-btn  m-0-auto"
              onClick={() => {
                resetValues();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      
    </Fragment>
  );
};

export default AddHigherSecondaryModal;
