import "./JobsDisplay.css";
import { Link } from "react-router-dom";
import Spinner from "components/elements/Spinner";

const JobsDisplay = ({ posts, setPage, page, cat, isFetching, isLoading }) => {
  return (
    <div className="jobs-display ">
      <h1 className="pb-2 capitalize">
        {cat === "" ? "All Jobs" : `${cat} Jobs`}
      </h1>
      {isLoading ? <Spinner small /> : null}
      {posts &&
        posts.results.map((post) => (
          <div className="job-post-single p-2" key={post._id}>
            <h2 className="title">{post.title}</h2>
            <p className="name pt-0 mb-1">{post.user_id.companyName}</p>
            <div className="flex mt-0 mb-0 flex-wrap">
              <div className="flex-column mr-4 mb-0 mt-0">
                <h4 className="mb-0">Location</h4>
                <p>{post.location}</p>
              </div>
              <div className="flex-column mr-4 mb-0 mt-0">
                <h4 className="mb-0">Start Date</h4>
                <p>{new Date(post.startDate).toLocaleDateString()}</p>
              </div>
              {post.jobType === "Internship" ? (
                <div className="flex-column mr-4 mb-0 mt-0">
                  <h4 className="mb-0">Duration</h4>
                  <p>{post.duration}</p>
                </div>
              ) : null}
              {post.jobType === "Internship" ? (
                <div className="flex-column mr-4 mb-0 mt-0">
                  <h4 className="mb-0">Stipend</h4>

                  {post.internshipType === "unpaid" ? (
                    <p>Unpaid</p>
                  ) : (
                    <p>{post.stipend} </p>
                  )}
                </div>
              ) : (
                <div className="flex-column mr-4 mb-0 mt-0">
                  <h4 className="mb-0">Salary</h4>
                  <p>{post.salary}</p>
                </div>
              )}
              <div className="flex-column mr-4 mb-0 mt-0">
                <h4 className="mb-0">Apply By</h4>
                <p>{new Date(post.applyBy).toLocaleDateString()}</p>
              </div>
            </div>
            <div className="tags pt-1">{/* {} */}</div>
            <Link to={{ pathname: `/details/${post._id}`, post }}>
              <button className="submit-btn pt-1">View Details</button>
            </Link>
          </div>
        ))}
    </div>
  );
};
export default JobsDisplay;
