import React from "react";
import MainLayout from "layout/MainLayout";

import { Redirect, Route } from "react-router-dom";

import useAuthStore from "stores/AuthStore";
import useUserStore from "stores/UserStore";
const UnAuthenticatedRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  /* TODO:Redirect to dashboard */
  const role = useUserStore((state) => state.role);
  const isRegistrationComplete = useUserStore(
    (state) => state.isRegistrationComplete
  );

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Redirect to="/addinfo" />
        ) : (
          <MainLayout>
            <Component {...props} />
          </MainLayout>
        )
      }
    />
  );
};

export default UnAuthenticatedRoute;
