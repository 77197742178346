import React from "react";

const Dashboard = () => {
  return (
    <div className="Dashboard">
      <div className="flex justify-space-between p-2">
        <h1 className="dashboard-title">Dashboard</h1>
        {/* <button className="submit-btn" type="submit" form="form1">
                    Save
                </button> */}
      </div>{" "}
    </div>
  );
};

export default Dashboard;
