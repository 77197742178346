import ProfileEdit from "views/dashboard/profile/ProfileEdit";
import "./Jobs.css";
const UserProfileEdit = () => {
  return (
    <div className="main-container">
      <div className="main-display-2">
        <ProfileEdit />
      </div>
    </div>
  );
};
export default UserProfileEdit;
