import Pagination from "helpers/Pagination";
import React from "react";
import { Link } from "react-router-dom";
import "./ViewJobPosts.css";

const TableData = (props) => {
  const { postsData, page, setPage, editstatusMutation } = props;
  console.log(postsData);
  return (
    <div className="dashboard-table">
      <table className="table table-bordere">
        <thead>
          <tr>
            {/* <th scope="col">UID</th> */}
            <th scope="col">Title</th>
            <th scope="col">Salary/Stipend</th>
            <th scope="col">Location</th>
            <th scope="col">Status</th>
            <th scope="col">Openings</th>
            <th scope="col"></th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {postsData.data &&
            postsData.data.results.map((post) => (
              <tr key={post._id}>
                {/* {logging(post.createdAt)} */}
                {/* <th scope="row">1</th> */}
                <td>{post.title}</td>
                <td>
                  {post.jobType == "Internship"
                    ? post.internshipType == "paid"
                      ? post.stipend
                      : "Unpaid"
                    : post.salary}
                </td>
                <td>{post.location}</td>
                <td>{post.isActive === true ? "Active" : "InActive"}</td>
                <td>{post.openings}</td>
                <td>
                  <div className="flex-column">
                    <Link
                      to={{
                        pathname: `/admin/posts/${post._id}`,
                        post,
                      }}
                    >
                      <button className="submit-btn mb-0">View</button>
                    </Link>
                    {/* <Link */}
                    {/*   to={{ */}
                    {/*     pathname: `/admin/posts/${post._id}/candidates`, */}
                    {/*     post, */}
                    {/*   }} */}
                    {/* > */}
                    {/*   <button className="submit-btn">Applied Candidates</button> */}
                    {/* </Link> */}
                  </div>
                </td>
                <td>
                  <button
                    className={
                      post.isActive === true
                        ? "p-1 cancel-btn"
                        : "p-1 green-btn"
                    }
                    onClick={() => {
                      editstatusMutation.mutate({
                        isActive: post.isActive,
                        post_id: post._id,
                      });
                    }}
                  >
                    {post.isActive === true ? "Deactivate" : "Activate"}
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {postsData.data && (
        <Pagination items={postsData.data} setPage={setPage} page={page} />
      )}
    </div>
  );
};

export default TableData;
