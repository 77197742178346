import React from "react";
import Header from "components/Header";

const MainLayout = ({ children }) => {
  return (
    <div>
      <Header />
      <div className="height-80"></div>

      {children}
    </div>
  );
};

export default MainLayout;
