import { Fragment, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "components/elements/ModalWrapper";
import CardWrapper from "components/elements/CardWrapperInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { addSkills } from "queries/userQueries";

const AddSkillsModal = ({
  setShowModal,
  showModal,
  skills,
  edit,
  setEdit,
  modalData,
  setModalData,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const modalRef = useRef();
  const queryClient = useQueryClient();

  const resetValues = () => {
    setModalData();
    setEdit(false);
    setShowModal(true);
  };

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      resetValues();
    }
  };

  const mutation = useMutation(addSkills, {
    onSuccess: () => {
      queryClient.invalidateQueries("userInfo");
      resetValues();
    },
    onError: () => {
      console.log("error");
    },
  });

  const onSubmit = async (data) => {
    try {
      if (edit === true) {
        const index = skills.findIndex((sk) => sk._id === modalData._id);
        skills[index].skill = data.skill;
        skills[index].expertise = data.expertise;
      } else {
        skills.push(data);
      }
      await mutation.mutate(skills);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Fragment>
      <div className="modal-wrapper" ref={modalRef} onClick={closeModal}>
        <div className="modal-container flex-column justify-content-around align-items-center p-2">
          <h2>Add Skills</h2>
          <div className="flex-column align-items-center justify-space-around">
            <form
              className="info-viewer justify-content-center flex-column mv-1"
              onSubmit={handleSubmit(onSubmit)}
            >
              <CardWrapper title="Skill">
                <div className="wrap-input">
                  <input
                    className="input"
                    id="skill"
                    defaultValue={modalData && modalData.skill}
                    name="skill"
                    placeholder="Enter your Skill"
                    ref={register({
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                  />
                </div>
                {errors.skill && (
                  <span className="is-danger">{errors.skill.message}</span>
                )}
              </CardWrapper>

              <CardWrapper title="Select Expertise">
                <select
                  className="classic mv-0"
                  name="expertise"
                  defaultValue={modalData && modalData.expertise}
                  ref={register({
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                >
                  <option value="beginner">Beginner</option>
                  <option value="intermediate">Intermediate</option>
                  <option value="expert">Expert</option>
                </select>
                {errors.expertise && (
                  <span className="is-danger">{errors.expertise.message}</span>
                )}
              </CardWrapper>

              <button className="submit-btn inline-block m-0-auto">Save</button>
            </form>
            <button
              className="cancel-btn"
              onClick={() => {
                resetValues();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddSkillsModal;
