import React from "react";
import MainLayout from "layout/MainLayout";

import { Route } from "react-router-dom";

const MainRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <MainLayout>
          <Component {...props} />
        </MainLayout>
      )}
    />
  );
};

export default MainRoute;
