const dev = "http://localhost:5000";
const prod = "https://vidpalbackend.ibrew-hub.com";
// const prod = "http://project.vps.webdock.io:5000";

export const baseURL = process.env.NODE_ENV === "development" ? dev : prod;

export const cityDataList = [
  "Bengaluru",
  "Mysore",
  "Hubli",
  "Mangalore",
  "Belgaum",
  "Gulbarga",
  "Davanagere",
  "Bellary",
  "Bijapur",
  "Shimoga",
  "Tumkur",
  "Raichur",
  "Bidar",
  "Hospet",
  "Hassan",
  "Gadag",
  "Udupi",
  "Robertson",
  "Bhadravati",
  "Chitradurga",
  "Kolar",
  "Mandya",
  "Chikmagalur",
  "Gangawati",
  "Bagalkot",
];
