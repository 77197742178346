import useErrorStore from "stores/ErrorStore";

const setErrorData = (res, resjson) => {
  useErrorStore.setState({
    message: resjson.error.message,
    status: resjson.error.status,
    statusRes: res.status,
    statusText: res.statusText,
  });
  throw new Error(res.statusText);
};

export { setErrorData };
