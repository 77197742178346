import React, { useState } from "react";
import InfoWrapperInput from "components/elements/InfoWrapperInput";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getSinglePost } from "queries/postsQueries";
import { Link, useParams, useHistory, Redirect } from "react-router-dom";
import { getCategory } from "queries/categoryQueries";
import { editPost } from "queries/postsQueries";
import { cityDataList } from "utils/constants";
import { useQuery, useMutation, useQueryClient } from "react-query";
import useErrorStore from "stores/ErrorStore";

const EditJobPost = (props) => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const post = useQuery(["posts", id], () => getSinglePost(id), {
    staleTime: 2000,
  });
  let history = useHistory();

  const { isLoading, isError, data, error } = useQuery(
    "categories",
    getCategory
  );
  const [disableBtn, setDisableBtn] = useState(false);
  const { register, handleSubmit, errors, watch } = useForm();
  const message = useErrorStore((state) => state.message);
  const watchJob = watch("jobType", props.jobType);
  const watchInternshipType = watch("internshipType", props.internshipType);
  const watchCTC = watch("ctc", props.ctc);
  const mutation = useMutation(editPost, {
    onSuccess: () => {
      queryClient.invalidateQueries("posts");
      setDisableBtn(false);
      history.push("/admin/posts");
    },
    onError: () => {
      setDisableBtn(false);
    },
  });
  const redirect = () => {
    // <Redirect to="/admin/post"
  };

  const onSubmit = async (data) => {
    try {
      console.log(data);
      data._id = id;
      await mutation.mutate({ data });
    } catch (error) {
      console.log(error);
    }
  };
  if (post.isLoading || isLoading) {
    return <span>Loading...</span>;
  }
  if (post.isError || isError) {
    return <span>error...</span>;
  }
  return (
    <div className="scroll-overflow">
      <div className="flex justify-space-between p-2">
        <h1 className="dashboard-title">Edit Post</h1>
      </div>
      <div className="dash-scrolls">
        <form className="info-viewer" onSubmit={handleSubmit(onSubmit)}>
          <InfoWrapperInput title="Job Type">
            <select
              className="mv-0 classic input"
              name="jobType"
              id="jobType"
              defaultValue={post.data.jobType}
              ref={register({
                required: {
                  value: true,
                  message: "This field is required",
                },
              })}
            >
              <option value="">Select Job Type</option>
              <option value={"Full Time Job"}>Full Time Job</option>
              <option value={"Part Time Job"}>Part Time Job</option>
              <option value={"Internship"}>Internship</option>

              {/* <option value="Developer">Developer</option> */}
            </select>
            {errors.jobType && (
              <span className="is-danger">{errors.jobType.message}</span>
            )}
          </InfoWrapperInput>

          <InfoWrapperInput
            title={watchJob == "Internship" ? "Internship Title" : "Job Title"}
          >
            <div className="wrap-input">
              <input
                className="input"
                id="title"
                name="title"
                placeholder="Enter your Title"
                defaultValue={post.data.title}
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
            </div>
            {errors.title && (
              <span className="is-danger">{errors.title.message}</span>
            )}
          </InfoWrapperInput>

          <InfoWrapperInput title="Location">
            <div className="wrap-input">
              <select
                className="classic input mv-0"
                name="location"
                id="location"
                defaultValue={post.data.location}
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              >
                <option value="">Select Location</option>
                {cityDataList.map((l) => (
                  <option value={l}>{l}</option>
                ))}
              </select>
            </div>
            {errors.location && (
              <span className="is-danger">{errors.location.message}</span>
            )}
          </InfoWrapperInput>

          <InfoWrapperInput title="Category">
            <select
              className="mv-0 classic input"
              name="categoryName"
              id="categoryName"
              defaultValue={data && post.data.categoryName}
              ref={register({
                required: {
                  value: true,
                  message: "This field is required",
                },
              })}
            >
              <option value="">Select Category</option>
              {data &&
                data.results.map((category) => (
                  <option key={category._id} value={category.title}>
                    {category.title}
                  </option>
                ))}

              {/* <option value="Developer">Developer</option> */}
            </select>
            {errors.categoryName && (
              <span className="is-danger">{errors.categoryName.message}</span>
            )}
          </InfoWrapperInput>

          <InfoWrapperInput title="Start date">
            <div className="wrap-input">
              <input
                className="input"
                id="startDate"
                name="startDate"
                type="date"
                placeholder="Enter your Start Date"
                defaultValue={new Date(post.data.startDate)
                  .toISOString()
                  .substring(0, 10)}
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
            </div>
            {errors.startDate && (
              <span className="is-danger">{errors.startDate.message}</span>
            )}
          </InfoWrapperInput>

          <InfoWrapperInput title="Apply by">
            <div className="wrap-input">
              <input
                className="input"
                id="applyBy"
                name="applyBy"
                type="date"
                defaultValue={new Date(post.data.applyBy)
                  .toISOString()
                  .substring(0, 10)}
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
            </div>
            {errors.applyBy && (
              <span className="is-danger">{errors.applyBy.message}</span>
            )}
          </InfoWrapperInput>

          <InfoWrapperInput
            title={
              watchJob == "Internship"
                ? "Internship Description"
                : "Job description"
            }
          >
            <div className="wrap-input">
              <textarea
                className="textarea"
                id="jobDescription"
                name="jobDescription"
                placeholder="Enter your Job Description"
                defaultValue={post.data.jobDescription}
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
            </div>
            {errors.jobDescription && (
              <span className="is-danger">{errors.jobDescription.message}</span>
            )}
          </InfoWrapperInput>

          <InfoWrapperInput title="Who Can Apply" optional="true">
            <div className="wrap-input">
              <textarea
                className="textarea"
                id="whoCanApply"
                name="whoCanApply"
                placeholder="Enter your Job Description"
                defaultValue={post.data.whoCanApply}
                ref={register()}
              />
            </div>
            {errors.whoCanApply && (
              <span className="is-danger">{errors.whoCanApply.message}</span>
            )}
          </InfoWrapperInput>

          {watchJob == "Internship" ? (
            <>
              <InfoWrapperInput title="Internship Type">
                <div className="wrap-input">
                  <select
                    className="mv-0 classic input"
                    id="internshipType"
                    name="internshipType"
                    defaultValue={post.data.internshipType}
                    ref={register({
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                  >
                    <option value="paid">Paid</option>
                    <option value="unpaid">Unpaid</option>
                  </select>
                </div>
                {errors.internshipType && (
                  <span className="is-danger">
                    {errors.internshipType.message}
                  </span>
                )}
              </InfoWrapperInput>

              {watchInternshipType == "paid" ? (
                <InfoWrapperInput title="Stipend /Month">
                  <div className="wrap-input">
                    <input
                      className="input"
                      id="stipend"
                      name="stipend"
                      placeholder="stipend/month"
                      defaultValue={post.data.stipend}
                      ref={register({
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      })}
                    />
                  </div>
                  {errors.stipend && (
                    <span className="is-danger">{errors.stipend.message}</span>
                  )}
                </InfoWrapperInput>
              ) : null}

              <InfoWrapperInput title="Duration">
                <div className="wrap-input">
                  <select
                    className="input mv-0 classic"
                    id="duration"
                    name="duration"
                    placeholder="duration/month"
                    defaultValue={post.data.duration}
                    ref={register({
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                  >
                    <option value="">Select Duration</option>
                    <option value="1 Month">1 Month</option>
                    <option value="2 Months">2 Months</option>
                    <option value="3 Months">3 Months</option>
                    <option value="4 Months">4 Months</option>
                    <option value="5 Months">5 Months</option>
                    <option value="6 Months">6 Months</option>
                  </select>
                </div>
                {errors.duration && (
                  <span className="is-danger">{errors.duration.message}</span>
                )}
              </InfoWrapperInput>
            </>
          ) : (
            <>
              <input
                type="checkbox"
                className="mb-1"
                name="ctc"
                defaultValue={post.data.ctc}
                id="ctc"
                ref={register()}
              />
              <label className="ml-1 mb-1" htmlFor="ctc">
                Is the total compensation provided in the form of CTC(Cost To
                Company)?
              </label>
              <InfoWrapperInput title={watchCTC == true ? "CTC" : "Salary"}>
                <div className="wrap-input">
                  <input
                    className="input"
                    id="salary"
                    name="salary"
                    placeholder={
                      watchCTC == true ? "Enter CTC" : "salary /month"
                    }
                    defaultValue={post.data.salary}
                    ref={register({
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                  />
                </div>
                {errors.salary && (
                  <span className="is-danger">{errors.salary.message}</span>
                )}
              </InfoWrapperInput>
            </>
          )}

          <InfoWrapperInput title="Perks" optional="true">
            <div className="wrap-input">
              <textarea
                className="textarea"
                id="perks"
                name="perks"
                placeholder="Enter Perks "
                defaultValue={post.data.perks}
                ref={register()}
              />
            </div>
            {errors.perks && (
              <span className="is-danger">{errors.perks.message}</span>
            )}
          </InfoWrapperInput>

          <InfoWrapperInput title="No. of Openings">
            <div className="wrap-input">
              <input
                className="input"
                id="openings"
                name="openings"
                placeholder="Enter your openings"
                defaultValue={post.data.openings}
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
            </div>
            {errors.openings && (
              <span className="is-danger">{errors.openings.message}</span>
            )}
          </InfoWrapperInput>

          <InfoWrapperInput title="Skills requried">
            <div className="wrap-input">
              <textarea
                className="textarea"
                id="skills"
                name="skills"
                placeholder="Enter skills required"
                defaultValue={post.data.skills}
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
            </div>
            {errors.skills && (
              <span className="is-danger">{errors.skills.message}</span>
            )}
          </InfoWrapperInput>

          <div className="flex justify-content-end">
            <Link to="/admin/posts">
              <button className="cancel-btn m-0 p-1">Cancel</button>
            </Link>
            <button
              className="submit-btn m-0 p-1"
              type="submit"
              disabled={disableBtn}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditJobPost;
