import { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import "./JobPostDetails.css";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import {
  applyForJob,
  getSinglePost,
  deleteAppliedJob,
} from "queries/postsQueries";
import ApplyModal from "views/modals/ApplyModal";

import TextFormatter from "helpers/TextFormatter";
import useDataStore from "stores/DataStore";
import useAuthStore from "stores/AuthStore";
import useUserStore from "stores/UserStore";
import { getUsersByID } from "queries/adminQueries";
import { successToast, errorToast } from "helpers/Toast";
const JobPostDetails = () => {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const user_id = useUserStore((state) => state.id);

  const [applied, setApplied] = useState(false);
  const [modalData, setModalData] = useState();
  const [applyModalInsHidden, setApplyModalIsHidden] = useState(true);
  const { id } = useParams();
  const { isLoading, data, isError } = useQuery(
    ["posts", id],
    () => getSinglePost(id),
    {
      onSuccess: (data) => {
        console.log("data");
      },
    }
  );
  const user =
    isLoggedIn &&
    useQuery(["users", user_id], () => getUsersByID(user_id), {
      onSuccess: (data) => {
        console.log("data users");
        data.appliedJobs.indexOf(id) !== -1 && setApplied(true);
      },
      onError: (error) => {
        errorToast("OOPS! Something went wrong");
        console.log(error);
      },
    });

  const mutation = useMutation(applyForJob, {
    onSuccess: (data) => {
      setApplied(true);
      successToast("Successfully applied for Job");
    },
    onError: (error) => {
      errorToast("OOPS! Something went wrong");
      console.log(error);
    },
  });
  const deleteApplicationmutation = useMutation(deleteAppliedJob, {
    onSuccess: (data) => {
      setApplied(false);
      successToast("Successfully deleted Application");
      console.log(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const storeURLData = () => {
    useDataStore.setState({ redirectData: `/details/${id}` });
  };

  // const isLoggedIn = () => localStorage.getItem("isLoggedIn");

  const applyFunction = () => {
    mutation.mutate(id);
  };
  const deleteApplication = () => {
    deleteApplicationmutation.mutate(id);
  };
  if (isLoading) {
    return <span>Loading...</span>;
  }
  if (isError) {
    return <span>error...</span>;
  }
  return (
    <>
      <div className="jobs-display job-post-details p-2">
        {applyModalInsHidden ? null : (
          <ApplyModal
            setShowModal={setApplyModalIsHidden}
            showModal={applyModalInsHidden}
            modalData={modalData}
            setModalData={setModalData}
          />
        )}
        <h2 className="title">
          {data.title}-{data.jobType}
        </h2>
        <p className="name pt-0 mb-1">{data.user_id.companyName}</p>

        <div className="flex mt-0 mb-0 flex-wrap">
          <div className="flex-column mr-4 mt-0 mb-0">
            <h4 className="mb-0">Location</h4>
            <p>{data.location}</p>
          </div>
          <div className="flex-column mr-4 mb-0 mt-0 ">
            <h4 className="mb-0">Start Date</h4>
            <p>{new Date(data.startDate).toLocaleDateString()}</p>
          </div>
          {data.jobType === "Internship" ? (
            <div className="flex-column mr-4 mb-0 mt-0">
              <h4 className="mb-0">Duration</h4>
              <p>{data.duration}</p>
            </div>
          ) : null}
          {data.jobType === "Internship" ? (
            <div className="flex-column mr-4 mb-0 mt-0">
              <h4 className="mb-0">Stipend</h4>

              {data.internshipType === "unpaid" ? (
                <p>Unpaid</p>
              ) : (
                <p>{data.stipend} </p>
              )}
            </div>
          ) : (
            <div className="flex-column mr-4 mb-0 mt-0">
              <h4 className="mb-0">Salary</h4>
              <p>{data.salary}</p>
            </div>
          )}
          <div className="flex-column mr-4 mb-0 mt-0">
            <h4 className="mb-0">Apply By</h4>
            <p>{new Date(data.applyBy).toLocaleDateString()}</p>
          </div>
        </div>
        <div className="flex-column mt-1 mb-1">
          <h4>About {data.user_id.companyName}</h4>
          {data.user_id.website && <p>{data.user_id.website}</p>}
          <TextFormatter text={data.user_id.bio} />
        </div>
        {data.whoCanApply && (
          <div className="flex-column mt-1 mb-1">
            <h4>Who can Apply</h4>
            <TextFormatter text={data.whoCanApply} />
          </div>
        )}
        {data.perks && (
          <div className="flex-column mt-1 mb-1">
            <h4>Perks</h4>
            <TextFormatter text={data.perks} />
            <p>{data.perks}</p>
          </div>
        )}
        <div className="flex-column mt-1 mb-1">
          <h4>Skills</h4>
          <p>{data.skills}</p>
        </div>

        {localStorage.getItem("authState") == "true" ? (
          localStorage.getItem("userRole") === "user" ? (
            <div className="m-auto">
              <button
                className={applied ? "cancel-btn pt-1" : "submit-btn pt-1"}
                onClick={applied ? deleteApplication : applyFunction}
              >
                {applied ? "Delete Application" : "Apply Now"}
              </button>
            </div>
          ) : null
        ) : (
          <div className="m-auto">
            <Link to="/login" onClick={storeURLData}>
              <button className="submit-btn pt-1">Login to Apply</button>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default JobPostDetails;
