import { Fragment, useRef } from "react";
import { useForm } from "react-hook-form";
import Modal from "components/elements/ModalWrapper";
import CardWrapper from "components/elements/CardWrapperInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { addCertificates } from "queries/userQueries";

const AddCertificatesModal = ({
  setShowModal,
  showModal,
  certificates,
  edit,
  setEdit,
  modalData,
  setModalData,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const modalRef = useRef();
  const queryClient = useQueryClient();

  const resetValues = () => {
    setModalData();
    setEdit(false);
    setShowModal(true);
  };

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      resetValues();
    }
  };

  const mutation = useMutation(addCertificates, {
    onSuccess: () => {
      queryClient.invalidateQueries("userInfo");
      resetValues();
    },
    onError: () => {
      console.log("error");
    },
  });

  const onSubmit = async (data) => {
    try {
      if (edit === true) {
        const index = certificates.findIndex((ed) => ed._id === modalData._id);
        certificates[index].institute = data.institute;
        certificates[index].organization = data.organization;
        certificates[index].startDate = data.startDate;
        certificates[index].endDate = data.endDate;
        certificates[index].description = data.description;
      } else {
        certificates.push(data);
      }
      await mutation.mutate(certificates);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Fragment>
      <div className="modal-wrapper" ref={modalRef} onClick={closeModal}>
        <div className="modal-container flex-column justify-content-around align-items-center p-2">
          <h2>Add Certificates</h2>
          <div className="flex-column align-items-center justify-space-around">
            <form
              className="info-viewer justify-content-center flex-column mv-1"
              onSubmit={handleSubmit(onSubmit)}
            >
              <CardWrapper title="Institute">
                <div className="wrap-input">
                  <input
                    className="input"
                    id="institute"
                    name="institute"
                    defaultValue={modalData && modalData.institute}
                    placeholder="Enter Institute"
                    ref={register({
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                  />
                </div>
                {errors.institute && (
                  <span className="is-danger">{errors.institute.message}</span>
                )}
              </CardWrapper>
              <CardWrapper title="Organization">
                <div className="wrap-input">
                  <input
                    className="input"
                    id="organization"
                    name="organization"
                    defaultValue={modalData && modalData.organization}
                    placeholder="Organization"
                    ref={register({
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                  />
                </div>
                {errors.organization && (
                  <span className="is-danger">
                    {errors.organization.message}
                  </span>
                )}
              </CardWrapper>
              <div className="flex justify-space-between">
                <CardWrapper title="Start Date">
                  <div className="wrap-input">
                    <input
                      className="input-half"
                      type="Date"
                      id="startDate"
                      name="startDate"
                      defaultValue={modalData && modalData.startDate}
                      placeholder="Start Date"
                      ref={register({
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      })}
                    />
                    <span className="symbol-input">
                      {/* <i className="fa fa-envelope" aria-hidden="true"></i> */}
                      <FontAwesomeIcon icon="envelope" symbol />
                    </span>
                  </div>
                  {errors.startDate && (
                    <span className="is-danger">
                      {errors.startDate.message}
                    </span>
                  )}
                </CardWrapper>
                <CardWrapper title="End Date">
                  <div className="wrap-input">
                    <input
                      className="input-half"
                      type="Date"
                      id="endDate"
                      name="endDate"
                      defaultValue={modalData && modalData.endDate}
                      placeholder="End Date"
                      ref={register({
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      })}
                    />
                    <span className="symbol-input">
                      {/* <i className="fa fa-envelope" aria-hidden="true"></i> */}
                      <FontAwesomeIcon icon="envelope" symbol />
                    </span>
                  </div>
                  {errors.endDate && (
                    <span className="is-danger">{errors.endDate.message}</span>
                  )}
                </CardWrapper>
              </div>
              <CardWrapper title="Description" optional="true">
                <div className="wrap-input">
                  <textarea
                    className="textarea"
                    id="description"
                    name="description"
                    defaultValue={modalData && modalData.description}
                    placeholder="Add Description"
                    ref={register()}
                  />
                </div>
                {errors.description && (
                  <span className="is-danger">
                    {errors.description.message}
                  </span>
                )}
              </CardWrapper>

              <button className="submit-btn inline-block m-0-auto">Save</button>
            </form>
            <button
              className="cancel-btn"
              onClick={() => {
                resetValues();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AddCertificatesModal;
