import useErrorStore from "stores/ErrorStore";
import { baseURL } from "utils/constants";
import { setErrorData } from "helpers/queryHelpers";
import { checkAuth } from "hooks/checkAuth";

export async function getCourse() {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  const res = await fetch(`${baseURL}/courses/admin `, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function getCourseById(id) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  console.log("******Get Course By ID******");
  const res = await fetch(`${baseURL}/courses/find?id=${id}`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function createCourse({ data }) {
  await checkAuth();

  // const user = localStorage.getItem("user");
  console.info("***** Create Course API *****");
  console.info(data);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(data),
  };
  const res = await fetch(`${baseURL}/courses`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function editCourseDetails({ data, course_id }) {
  await checkAuth();

  // const user = localStorage.getItem("user");
  console.info("******Edit Course Details******");
  console.info(data);
  const newData = { ...data, id: course_id };
  console.log("new data");
  console.log(newData);
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(newData),
  };
  const res = await fetch(`${baseURL}/courses`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function deleteCourse(data) {
  await checkAuth();
  // const user = localStorage.getItem("user");
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify({ course_id: data }),
  };
  const res = await fetch(`${baseURL}/courses`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function getCurriculum(course_id) {
  await checkAuth();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
  };
  const res = await fetch(
    `${baseURL}/courses/curriculum?course_id=${course_id}`,
    requestOptions
  );
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function createNewSection(data) {
  await checkAuth();

  // const user = localStorage.getItem("user");
  console.info("create new section data");
  console.info(data);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(data),
  };
  const res = await fetch(`${baseURL}/courses/curriculum`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function editSection(data) {
  await checkAuth();

  // const user = localStorage.getItem("user");
  console.info("edit section data");
  console.info(data);
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(data),
  };
  const res = await fetch(`${baseURL}/courses/curriculum`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}
export async function sortSection(data) {
  await checkAuth();

  // const user = localStorage.getItem("user");
  console.info("Sort Section data");
  console.info(data);
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(data),
  };
  const res = await fetch(`${baseURL}/courses/curriculum/sort`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function deleteSection(data) {
  await checkAuth();

  // const user = localStorage.getItem("user");
  console.info("Delete section data");
  console.info(data);
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(data),
  };
  const res = await fetch(`${baseURL}/courses/curriculum`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function createNewLecture(data) {
  await checkAuth();

  // const user = localStorage.getItem("user");
  console.info("create new Lecture data");
  console.info(data);
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(data),
  };
  const res = await fetch(
    `${baseURL}/courses/curriculum/lecture`,
    requestOptions
  );
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function editLecture(data) {
  await checkAuth();

  // const user = localStorage.getItem("user");
  console.info("edit Lecture data");
  console.info(data);
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(data),
  };
  const res = await fetch(
    `${baseURL}/courses/curriculum/lecture`,
    requestOptions
  );
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}
export async function sortLecture(data) {
  await checkAuth();

  // const user = localStorage.getItem("user");
  console.info("Sort Lecture data");
  console.info(data);
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(data),
  };
  const res = await fetch(
    `${baseURL}/courses/curriculum/lecture/sort`,
    requestOptions
  );
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function addLectureURL({ data, id }) {
  await checkAuth();

  // const user = localStorage.getItem("user");
  console.info("edit Lecture data");
  console.info(data);
  const newData = { ...data, id: id };
  console.log("New data");
  console.log(newData);
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(newData),
  };
  const res = await fetch(
    `${baseURL}/courses/curriculum/lecture/add-url`,
    requestOptions
  );
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function addCoursePreview(data) {
  await checkAuth();

  // const user = localStorage.getItem("user");
  console.info("**** Course Preview ****");
  console.info(data);

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(data),
  };
  const res = await fetch(
    `${baseURL}/courses/curriculum/lecture/add-course-preview`,
    requestOptions
  );
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function lectureQuizQuestionsOperations({ data, id }) {
  await checkAuth();

  // const user = localStorage.getItem("user");
  console.info("edit Lecture data");
  console.info(data);
  const newData = { quizQuestions: data, id: id };
  console.log("New data");
  console.log(newData);
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(newData),
  };
  const res = await fetch(
    `${baseURL}/courses/curriculum/lecture/add-quiz`,
    requestOptions
  );
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function coursePricing(newData) {
  await checkAuth();

  // const user = localStorage.getItem("user");
  console.info("Pricng Operations");
  console.info(newData);
  // const newData = { data, id: id };
  // console.log("New data");
  // console.log(newData);
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(newData),
  };
  const res = await fetch(`${baseURL}/courses/pricing`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}
export async function deleteLecture(data) {
  await checkAuth();

  // const user = localStorage.getItem("user");
  console.info("Delete Section data");
  console.info(data);
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(data),
  };
  const res = await fetch(
    `${baseURL}/courses/curriculum/lecture`,
    requestOptions
  );
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function publishCourse(course_id) {
  await checkAuth();
  console.log(course_id);
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify({ course_id }),
  };
  const res = await fetch(`${baseURL}/courses/publish`, requestOptions);
  const resjson = await res.json();
  return resjson;
}
