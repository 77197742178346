import InfoWrapperInput from "components/elements/InfoWrapperInput";
import { coursePricing, createCourse } from "queries/courseQueries";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { Link, useHistory } from "react-router-dom";
import "./AddNewCoursePage.css";

const Pricing = (props) => {
  const { cour } = props;

  const queryClient = useQueryClient();
  const { register, handleSubmit, errors, getValues } = useForm();

  const [disableBtn, setDisableBtn] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isDiscount, setDiscount] = useState();
  const [image, setImage] = React.useState({ preview: "", raw: "" });

  const mutation = useMutation(coursePricing, {
    onSuccess: () => {
      queryClient.invalidateQueries("courses");
      setDisableBtn(false);
    },
    onError: () => {
      setDisableBtn(false);
    },
  });

  const onSubmit = (data) => {
    console.log(data);
    const newData = { pricing: { ...data }, id: cour.data._id };
    try {
      mutation.mutate(newData);
    } catch (error) {
      console.log("error");
      console.log(error);
    }
  };
  const valueIsFree = getValues("isFree");
  const valueIsDiscount = getValues("isDiscount");
  return (
    <main>
      <section>
        <h2 align="center">Course Value</h2>
      </section>
      <section className="course-form-wrapper">
        <form className="info-viewer mt-2" onSubmit={handleSubmit(onSubmit)}>
          <InfoWrapperInput title="Course Price(₹)">
            <label>
              <input
                type="checkbox"
                id="isFree"
                name="isFree"
                defaultValue={cour.data.pricing.isFree}
                ref={register()}
                defaultChecked={isChecked}
                onChange={() => setChecked(!isChecked)}
              />
              &nbsp; &nbsp; Is this course free?
            </label>
            <div className="wrap-input">
              <input
                className="input"
                id="price"
                name="price"
                defaultValue={cour.data.pricing.price}
                placeholder="Enter your course price"
                type="number"
                ref={register({
                  validate: {
                    required: (value) => {
                      if (!value && !isChecked) return "This field is required";
                      return true;
                    },
                  },
                })}
                disabled={isChecked}
              />
            </div>
            {errors.price && !isChecked && (
              <span className="is-danger">{errors.price.message}</span>
            )}
          </InfoWrapperInput>
          {!isChecked && (
            <InfoWrapperInput title="Course Discount(₹)">
              <label>
                <input
                  type="checkbox"
                  id="isDiscount"
                  name="isDiscount"
                  defaultValue={cour.data.pricing.isDiscount}
                  ref={register()}
                  defaultChecked={isDiscount}
                  onChange={() => setDiscount(!isDiscount)}
                />
                &nbsp; &nbsp; Does this course have a discount?
              </label>
              <div className="wrap-input">
                <input
                  className="input"
                  id="discount"
                  name="discount"
                  defaultValue={cour.data.pricing.discount}
                  placeholder="Enter your course discount"
                  type="number"
                  ref={register({
                    validate: {
                      required: (value) => {
                        if (!value && isDiscount)
                          return "This field is required";
                        return true;
                      },
                    },
                  })}
                  disabled={!isDiscount}
                />
              </div>
              {errors.discount && isDiscount && (
                <span className="is-danger">{errors.discount.message}</span>
              )}
            </InfoWrapperInput>
          )}
          <div className="flex justify-content-end">
            <Link to="/admin/courses">
              <button className="cancel-btn m-0 p-1">Cancel</button>
            </Link>
            <button
              className="submit-btn m-0 p-1"
              type="submit"
              disabled={disableBtn}
            >
              Save
            </button>
          </div>
        </form>
      </section>
    </main>
  );
};

export default Pricing;
