import { Fragment, useRef } from "react";
import { useForm } from "react-hook-form";
import Modal from "components/elements/ModalWrapper";
import CardWrapper from "components/elements/CardWrapperInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { applyForJob } from "queries/postsQueries";
import { errorToast } from "helpers/Toast";
import { useHistory } from "react-router-dom";
const ApplyModal = ({
  setShowModal,
  showModal,
  modalData,
  setModalData,
  // callback,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const modalRef = useRef();
  const history = useHistory();
  const queryClient = useQueryClient();
  const post_id = modalData._id;
  const resetValues = () => {
    // setModalData();
    // setShowModal(true);
    history.push("/");
    errorToast("Error: Information was not updated");
  };

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      resetValues();
    }
  };

  const mutation = useMutation(applyForJob, {
    onSuccess: () => {
      // callback();
      resetValues();
    },
    onError: () => {
      console.log("error");
      errorToast("Error: Information was not updated");
    },
  });

  const onSubmit = async (data) => {
    try {
      console.log(data);
      await mutation.mutate({ data, post_id });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Fragment>
      <div className="modal-wrapper" ref={modalRef} onClick={closeModal}>
        <div className="modal-container flex-column justify-content-around align-items-center p-2">
          <h2>
            {modalData.title} at {modalData.user_id.companyName}
          </h2>
          <div className="flex-column align-items-center justify-space-around">
            <form
              className="info-viewer justify-content-center flex-column mv-1"
              onSubmit={handleSubmit(onSubmit)}
            >
              <CardWrapper title="Why should we hire you?">
                <div className="wrap-input">
                  <textarea
                    className="textarea"
                    id="reason"
                    name="reason"
                    defaultValue={modalData && modalData.reason}
                    placeholder="Express your thoughts on why you are suitable this Internship/Job? Also mention relevant skills and experience"
                    ref={register({
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                  />
                </div>
                {errors.reason && (
                  <span className="is-danger">{errors.reason.message}</span>
                )}
              </CardWrapper>

              <button className="submit-btn m-0-auto">Save</button>
            </form>
            <button
              className="cancel-btn  m-0-auto"
              onClick={() => {
                resetValues();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ApplyModal;
