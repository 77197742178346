import { baseURL } from "utils/constants";
import { checkAuth } from "hooks/checkAuth";
import { setErrorData } from "helpers/queryHelpers";

export async function getPosts(page, isActive, cat, loc) {
  await checkAuth();
  let newPage = "";
  let newActive = "";
  let newCat = "";
  let newLoc = "";
  if (page) {
    newPage = `page=${page}`;
  }
  if (isActive) {
    newActive = `&isActive=${isActive}`;
  } else {
    newActive = `&isActive=false`;
  }

  if (cat) {
    newCat = `&cat=${cat}`;
  }
  if (loc) {
    newLoc = `&loc=${loc}`;
  }

  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  console.log("getAdmin Posts");
  console.log(`cat is ${cat}`);
  console.log(`loc is ${loc}`);
  console.log(`isActive is ${isActive}`);
  const res = await fetch(
    `${baseURL}/posts?${newPage}&limit=10${newCat}${newLoc}${newActive}`,
    requestOptions
  );
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);

  return resjson;
}

export async function getEmpPosts(page, user_id, isActive = true) {
  await checkAuth();

  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };
  console.log("getEmpPosts");

  const res = await fetch(
    `${baseURL}/posts/emp?page=${page}&limit=10&user_id=${user_id}&isActive=${isActive}`,
    requestOptions
  );
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function getSinglePost(id) {
  await checkAuth();

  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  const res = await fetch(`${baseURL}/posts/find?id=${id}`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function createPost({ data }) {
  await checkAuth();

  // const user = localStorage.getItem("user");
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(data),
  };
  const res = await fetch(`${baseURL}/posts`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}
export async function editPost({ data }) {
  await checkAuth();

  // const user = localStorage.getItem("user");
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify(data),
  };

  const res = await fetch(`${baseURL}/posts`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function statusControllerPost({ post_id, isActive }) {
  await checkAuth();

  // const user = localStorage.getItem("user");
  console.log(post_id);
  console.log(isActive);
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify({
      post_id,
      isActive,
    }),
  };

  const res = await fetch(`${baseURL}/posts/editstatus`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function applyForJob(id) {
  await checkAuth();

  // const user = localStorage.getItem("user");
  console.log(id);
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify({
      id,
    }),
  };

  const res = await fetch(`${baseURL}/applied-jobs/create`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}

export async function deleteAppliedJob(id) {
  await checkAuth();

  // const user = localStorage.getItem("user");
  console.log(id);
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("accessToken"),
    },
    body: JSON.stringify({
      id,
    }),
  };

  const res = await fetch(`${baseURL}/applied-jobs/delete`, requestOptions);
  const resjson = await res.json();

  if (!res.ok) setErrorData(res, resjson);
  return resjson;
}
