import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import useCourseCurriculumQueries from "./useCourseCurriculumQueries";

const useCourseCurriculum = (props) => {
  const { course_id } = props;
  const [sectionIsHidden, setSectionIsHidden] = React.useState(true);
  const [lectureIsHidden, setLectureIsHidden] = React.useState(true);
  const [sortIsHidden, setSortIsHidden] = React.useState(true);
  const [lessonSortIsHidden, setLessonSortIsHidden] = useState(true);
  const [isEdit, setEdit] = useState(false);
  const [editData, setEditData] = useState("");
  const [sortLessonValue, setSortLessonValue] = useState();
  const [temperaryCurriculumData, setTemperaryCurriculumData] = useState();
  const [temperaryData, setTemperaryData] = useState("");

  const {
    isLoading,
    isError,
    error,
    curriculumData,
    sectionMutation,
    editSectionMutation,
    sortSectionMutation,
    deleteSectionMutation,
    addLectureMutation,
    editLectureMutation,
    sortLectureMutation,
    deleteLectureMutation,
  } = useCourseCurriculumQueries(
    course_id,
    setEdit,
    setEditData,
    setSectionIsHidden,
    setLectureIsHidden,
    setSortIsHidden,
    setLessonSortIsHidden,
    setSortLessonValue,
    temperaryCurriculumData,
    setTemperaryCurriculumData
  );

  const { register, handleSubmit, errors } = useForm();
  const modalRef = useRef();

  const openSectionModal = () => {
    setSectionIsHidden(false);
  };

  const onEditSectionClickHandler = (value) => {
    setEdit(true);
    setEditData(value);

    console.log("Edit data value is :");
    console.log(value);

    setSectionIsHidden(false);
  };

  const onDeleteSectionClickHandler = (value) => {
    console.log("Delete section");
    const newData = { id: value._id, course_id: course_id };
    deleteSectionMutation.mutate(newData);
  };
  const openLectureModal = (value, type) => {
    setTemperaryData({ value: value, type: type });
    console.log("Temperary data stored after lecture modal open is");
    console.log(value);
    setLectureIsHidden(false);
  };

  const sortSection = () => {
    setSortIsHidden(false);
  };
  const sortLesson = (value) => {
    console.log(value);
    setSortLessonValue(value);
    setLessonSortIsHidden(false);
  };

  const onEditLectureClickHandler = (value) => {
    setEdit(true);
    setEditData(value);

    console.log("Edit data value is :");
    console.log(value);

    setLectureIsHidden(false);
  };

  const onDeleteLectureClickHandler = (value) => {
    console.log("Delete Lecture");
    deleteLectureMutation.mutate(value);
  };

  const onSectionSubmit = (data) => {
    console.log("onSectionSubmit");
    console.log(data);
    if (isEdit) {
      console.log("new edit data");

      // curriculdata._id is passed to find the element by id in backend
      const newData = { ...data, id: editData._id };

      editSectionMutation.mutate(newData);
    } else {
      const newData = { ...data, id: curriculumData._id, course_id: course_id };
      sectionMutation.mutate(newData);
    }
  };

  const onLectureSubmit = (data) => {
    console.log("onLecture Submit");
    if (isEdit) {
      console.log("new edit data");
      const newData = { content: { ...data }, id: editData._id };
      console.log(newData);

      editLectureMutation.mutate(newData);
    } else {
      const newData = {
        ...data,
        lectureType: temperaryData.type,
        course_id: course_id,
        section_id: temperaryData.value._id,
      };
      console.log(newData);

      addLectureMutation.mutate(newData);
    }
  };

  const onSortSave = () => {
    console.log(temperaryCurriculumData);
    const newTempArray = [];
    temperaryCurriculumData.map((content) => {
      newTempArray.push(content._id);
    });
    console.log("The new data onSortSave");
    const newData = { newSections: newTempArray, course_id: course_id };
    console.log(newData);

    sortSectionMutation.mutate(newData);
    // setCurriculumData(temperaryCurriculumData);
  };

  const onLessonSortSave = () => {
    console.log("onLessonSortSave");
    const newTempArray = [];
    temperaryCurriculumData.map((content) => {
      newTempArray.push(content._id);
    });
    const newData = {
      newLectures: newTempArray,
      section_id: curriculumData.sections[sortLessonValue]._id,
    };
    console.log("The new data onLessosSortSave");
    console.log(newData);
    sortLectureMutation.mutate(newData);
    // setCurriculumData(temperaryCurriculumData);
    /* setLessonSortIsHidden(true); */
  };

  return {
    sectionIsHidden,
    setSectionIsHidden,
    lectureIsHidden,
    setLectureIsHidden,
    sortIsHidden,
    setSortIsHidden,
    lessonSortIsHidden,
    setLessonSortIsHidden,
    isEdit,
    setEdit,
    editData,
    setEditData,
    temperaryCurriculumData,
    setTemperaryCurriculumData,
    isError,
    curriculumData,
    error,
    isLoading,
    sortLessonValue,
    setSortLessonValue,
    register,
    handleSubmit,
    errors,
    modalRef,
    openSectionModal,
    onEditSectionClickHandler,
    onDeleteSectionClickHandler,
    onEditLectureClickHandler,
    onDeleteLectureClickHandler,
    openLectureModal,
    sortSection,
    sortLesson,
    onSectionSubmit,
    onLectureSubmit,
    onSortSave,
    onLessonSortSave,
    temperaryData,
    setTemperaryData,
  };
};

export default useCourseCurriculum;
