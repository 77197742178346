import React from "react";
import "./AddNewCoursePage.css";
import { useForm, useFieldArray } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams, useHistory, Redirect } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import useErrorStore from "stores/ErrorStore";
import InfoWrapperInput from "components/elements/InfoWrapperInput";
import { getCategory } from "queries/categoryQueries";
import { createCourse } from "queries/courseQueries";

const AddNewCoursePageTwo = () => {
  let history = useHistory();
  const queryClient = useQueryClient();
  const [disableBtn, setDisableBtn] = React.useState(false);

  const [image, setImage] = React.useState({ preview: "", raw: "" });

  const handleChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image.raw);

    await fetch("YOUR_URL", {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: formData,
    });
  };

  const mutation = useMutation(createCourse, {
    onSuccess: () => {
      queryClient.invalidateQueries("courses");
      setDisableBtn(false);
      history.push("/admin/new-course-pricing");
    },
    onError: () => {
      setDisableBtn(false);
    },
  });

  const onSubmit = () => {
    console.log("test data");
  };

  return (
    <main>
      <section>
        <div className="step-wrapper m-2">
          <div className="step blue-step" />
          <div className="step blue-step" />
          <div className="step gray-step" />
        </div>
        <h2 align="center">Course Media</h2>
        <p align="center">Lorem Ipsum test content</p>
      </section>
      <section className="course-form-wrapper">
        <form className="info-viewer mt-2" onSubmit={onSubmit}>
          <InfoWrapperInput title="Course Thumbnail">
            <div className="flex-column align-items-center">
              <label
                htmlFor="upload-button"
                className="flex-column align-items-center"
              >
                {image.preview ? (
                  <img
                    src={image.preview}
                    alt="dummy"
                    width="300"
                    height="300"
                  />
                ) : (
                  <>
                    <span className="fa-stack fa-2x mt-3 mb-2  ">
                      <i className="fas fa-circle fa-stack-2x" />
                      <i className="far fa-image fa-stack-1x fa-inverse" />
                    </span>
                    <h5 className="text-center">Upload your photo</h5>
                  </>
                )}
              </label>
              <input
                type="file"
                id="upload-button"
                style={{ display: "none" }}
                onChange={handleChange}
              />
              <br />
              <button className="submit-btn" onClick={handleUpload}>
                Save Thumbnail
              </button>
            </div>
          </InfoWrapperInput>
        </form>
      </section>
    </main>
  );
};

export default AddNewCoursePageTwo;
