import React, { useRef } from "react";
import "./ModalWrapper.css";
const ModalWrapper = ({ children, title, setShowModal, showModal }) => {
  const modalRef = useRef();

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(true);
    }
  };

  // const keyPress = useCallback(
  //   (e) => {
  //     if (e.key === "Escape" && showModal) {
  //       setShowModal(true);
  //       console.log("I pressed");
  //     }
  //   },
  //   [setShowModal, showModal]
  // );

  // useEffect(() => {
  //   document.addEventListener("keydown", keyPress);
  //   return () => document.removeEventListener("keydown", keyPress);
  // }, [keyPress]);
  return (
    <div className="modal-wrapper" ref={modalRef} onClick={closeModal}>
      <div className="modal-container flex-column justify-content-around align-items-center p-2">
        <h4>{title}</h4>
        <div className="flex-column align-items-center justify-space-around">
          {children}
        </div>
      </div>
    </div>
  );
};
export default ModalWrapper;
