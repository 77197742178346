const TextFormatter = (text) => {
  const textObject = {
    text: text.text || "",
  };
  console.log(textObject);
  const textData = textObject.text.split("\n");
  return (
    <div>
      {textData.map((t, index) => (
        <p className="space" key={index}>
          {t}
        </p>
      ))}
    </div>
  );
};
export default TextFormatter;
