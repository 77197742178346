import { Link, useLocation, useParams } from "react-router-dom";
import InfoWrapper from "components/elements/InfoWrapper";
import { getSinglePost } from "queries/postsQueries";
import { useQuery, useMutation, useQueryClient } from "react-query";

import TextFormatter from "helpers/TextFormatter";

const JobPost = (props) => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const post = useQuery(["posts", id], () => getSinglePost(id), {
    staleTime: 2000,
  });

  if (post.isLoading) {
    return <span>Loading...</span>;
  }
  if (post.isError) {
    return <span>error...</span>;
  }
  return (
    <div className="job-post">
      <div className="flex justify-space-between p-2">
        <h1 className="dashboard-title">Post Details</h1>
        <Link to={`/admin/editpost/${post.data._id}`}>
          <button className="submit-btn">Edit</button>
        </Link>
      </div>
      <div className="info-viewer">
        <InfoWrapper title="Job type" description={post.data.jobType} />
        <InfoWrapper
          title={
            post.data.jobType == "Internship" ? "Internship Title" : "Job Title"
          }
          description={post.data.title}
        />
        <InfoWrapper title="Location" description={post.data.location} />
        <InfoWrapper title="Category" description={post.data.categoryName} />
        <InfoWrapper
          title="Start date"
          description={new Date(post.data.startDate).toLocaleDateString()}
        />
        <InfoWrapper
          title="Apply by"
          description={new Date(post.data.applyBy).toLocaleDateString()}
        />
        {post.data.jobType == "Internship" ? (
          <>
            <InfoWrapper
              title="Internship Type"
              description={post.data.internshipType}
            />
            <InfoWrapper title="Duration" description={post.data.duration} />
            {post.data.internshipType == "paid" ? (
              <InfoWrapper title="Stipend" description={post.data.stipend} />
            ) : null}
          </>
        ) : (
          <InfoWrapper title="Salary" description={post.data.salary} />
        )}
        <InfoWrapper title="No. of Openings" description={post.data.openings} />
        <InfoWrapper title="Skills Required" description={post.data.skills} />
        <InfoWrapper
          title="Job Description"
          description={<TextFormatter text={post.data.jobDescription} />}
        />
        {post.data.whoCanApply != "" ? (
          <InfoWrapper
            title="Who Can Apply"
            description={<TextFormatter text={post.data.whoCanApply} />}
          />
        ) : null}
        {post.data.perks != "" ? (
          <InfoWrapper
            title="Perks"
            description={<TextFormatter text={post.data.perks} />}
          />
        ) : null}
      </div>
    </div>
  );
};
export default JobPost;
