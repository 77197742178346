import Profile from "views/dashboard/profile/Profile" ;
import "./Jobs.css";
import { Redirect, useHistory } from "react-router-dom";
import useDataStore from "stores/DataStore";

const UserProfile = () => {
  const history = useHistory();
  const redirectData = useDataStore((state) => state.redirectData);
  const removeRedirectData = useDataStore((state) => state.removeRedirectData);
  if (redirectData !== "") {
    // removeRedirectData();
    // useDataStore.setState({ redirectData: "" });
    console.log(redirectData);
    // <Redirect to={`${redirectData}`} />;
    // return <Redirect to="/" />;
    history.push(redirectData);
    removeRedirectData();
    console.log(" redireg");
  }
  return (
    <div className="main-container">
      <div className="main-display-2">
        <Profile />
      </div>
    </div>
  );
};
export default UserProfile;
