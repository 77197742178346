import React, { useState } from "react";
import InfoWrapperInput from "components/elements/InfoWrapperInput";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory } from "react-router-dom";
import { getCategory } from "queries/categoryQueries";
import { createPost } from "queries/postsQueries";
import { cityDataList } from "utils/constants";

import { useQuery, useMutation, useQueryClient } from "react-query";
import useErrorStore from "stores/ErrorStore";

const NewJobPost = (props) => {
  let history = useHistory();

  const queryClient = useQueryClient();
  const [disableBtn, setDisableBtn] = useState(false);
  const { register, handleSubmit, errors, watch } = useForm();
  const message = useErrorStore((state) => state.message);
  const { isLoading, isError, data, error } = useQuery(
    "categories",
    getCategory
  );
  const watchJob = watch("jobType", props.jobType);
  const watchInternshipType = watch("internshipType", props.internshipType);
  const watchCTC = watch("ctc", props.ctc);
  const mutation = useMutation(createPost, {
    onSuccess: () => {
      queryClient.invalidateQueries("posts");
      setDisableBtn(false);
      history.push("/admin/posts");
    },
    onError: () => {
      setDisableBtn(false);
    },
  });

  const onSubmit = async (data) => {
    try {
      console.log(data);
      await mutation.mutate({ data });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="scroll-overflow">
      <div className="flex justify-space-between p-2">
        <h1 className="dashboard-title">Edit Post</h1>
      </div>
      <div className="dash-scrolls">
        <form className="info-viewer" onSubmit={handleSubmit(onSubmit)}>
          <InfoWrapperInput title="Job Type">
            <select
              className="mv-0 classic input"
              name="jobType"
              id="jobType"
              defaultValue="Full Time Job"
              ref={register({
                required: {
                  value: true,
                  message: "This field is required",
                },
              })}
            >
              <option value="">Select Job Type</option>
              <option value={"Full Time Job"}>Full Time Job</option>
              <option value={"Part Time Job"}>Part Time Job</option>
              <option value={"Internship"}>Internship</option>

              {/* <option value="Developer">Developer</option> */}
            </select>
            {errors.jobType && (
              <span className="is-danger">{errors.jobType.message}</span>
            )}
          </InfoWrapperInput>

          <InfoWrapperInput
            title={watchJob == "Internship" ? "Internship Title" : "Job Title"}
          >
            <div className="wrap-input">
              <input
                className="input"
                id="title"
                name="title"
                placeholder="Enter your Title"
                defaultValue="Developer"
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
            </div>
            {errors.title && (
              <span className="is-danger">{errors.title.message}</span>
            )}
          </InfoWrapperInput>

          <InfoWrapperInput title="Location">
            <div className="wrap-input">
              <select
                className="classic input mv-0"
                name="location"
                id="location"
                defaultValue="Mysore"
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              >
                <option value="">Select Location</option>
                {cityDataList.map((l) => (
                  <option value={l}>{l}</option>
                ))}
              </select>
            </div>
            {errors.location && (
              <span className="is-danger">{errors.location.message}</span>
            )}
          </InfoWrapperInput>
          {data && (
            <InfoWrapperInput title="Category">
              <select
                className="mv-0 classic input"
                name="categoryName"
                id="categoryName"
                defaultValue="DEVELOPER"
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              >
                <option value="">Select Category</option>
                {data &&
                  data.results.map((category) => (
                    <option key={category._id} value={category.title}>
                      {category.title}
                    </option>
                  ))}

                {/* <option value="Developer">Developer</option> */}
              </select>
              {errors.categoryName && (
                <span className="is-danger">{errors.categoryName.message}</span>
              )}
            </InfoWrapperInput>
          )}
          <InfoWrapperInput title="Start date">
            <div className="wrap-input">
              <input
                className="input"
                id="startDate"
                name="startDate"
                type="date"
                placeholder="Enter your Start Date"
                defaultValue="2021-03-13"
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
            </div>
            {errors.startDate && (
              <span className="is-danger">{errors.startDate.message}</span>
            )}
          </InfoWrapperInput>

          <InfoWrapperInput title="Apply by">
            <div className="wrap-input">
              <input
                className="input"
                id="applyBy"
                name="applyBy"
                type="date"
                defaultValue="2021-05-09"
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
            </div>
            {errors.applyBy && (
              <span className="is-danger">{errors.applyBy.message}</span>
            )}
          </InfoWrapperInput>

          <InfoWrapperInput
            title={
              watchJob == "Internship"
                ? "Internship Description"
                : "Job description"
            }
          >
            <div className="wrap-input">
              <textarea
                className="textarea"
                id="jobDescription"
                name="jobDescription"
                placeholder="Enter your Job Description"
                defaultValue="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
            </div>
            {errors.jobDescription && (
              <span className="is-danger">{errors.jobDescription.message}</span>
            )}
          </InfoWrapperInput>

          <InfoWrapperInput title="Who Can Apply" optional="true">
            <div className="wrap-input">
              <textarea
                className="textarea"
                id="whoCanApply"
                name="whoCanApply"
                placeholder="Enter your Job Description"
                defaultValue="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
                ref={register()}
              />
            </div>
            {errors.whoCanApply && (
              <span className="is-danger">{errors.whoCanApply.message}</span>
            )}
          </InfoWrapperInput>

          {watchJob == "Internship" ? (
            <>
              <InfoWrapperInput title="Internship Type">
                <div className="wrap-input">
                  <select
                    className="mv-0 classic input"
                    id="internshipType"
                    name="internshipType"
                    ref={register({
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                  >
                    <option value="paid">Paid</option>
                    <option value="unpaid">Unpaid</option>
                  </select>
                </div>
                {errors.internshipType && (
                  <span className="is-danger">
                    {errors.internshipType.message}
                  </span>
                )}
              </InfoWrapperInput>

              {watchInternshipType == "paid" ? (
                <InfoWrapperInput title="Stipend /Month">
                  <div className="wrap-input">
                    <input
                      className="input"
                      id="stipend"
                      name="stipend"
                      placeholder="stipend/month"
                      defaultValue="90000"
                      ref={register({
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      })}
                    />
                  </div>
                  {errors.stipend && (
                    <span className="is-danger">{errors.stipend.message}</span>
                  )}
                </InfoWrapperInput>
              ) : null}

              <InfoWrapperInput title="Duration">
                <div className="wrap-input">
                  <select
                    className="input mv-0 classic"
                    id="duration"
                    name="duration"
                    placeholder="duration/month"
                    ref={register({
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                  >
                    <option value="">Select Duration</option>
                    <option value="1 Month">1 Month</option>
                    <option value="2 Months">2 Months</option>
                    <option value="3 Months">3 Months</option>
                    <option value="4 Months">4 Months</option>
                    <option value="5 Months">5 Months</option>
                    <option value="6 Months">6 Months</option>
                  </select>
                </div>
                {errors.duration && (
                  <span className="is-danger">{errors.duration.message}</span>
                )}
              </InfoWrapperInput>
            </>
          ) : (
            <>
              <input
                type="checkbox"
                className="mb-1"
                name="ctc"
                id="ctc"
                ref={register()}
              />
              <label className="ml-1 mb-1" htmlFor="ctc">
                Is the total compensation provided in the form of CTC(Cost To
                Company)?
              </label>
              <InfoWrapperInput title={watchCTC == true ? "CTC" : "Salary"}>
                <div className="wrap-input">
                  <input
                    className="input"
                    id="salary"
                    name="salary"
                    placeholder={
                      watchCTC == true ? "Enter CTC" : "salary /month"
                    }
                    defaultValue="90000"
                    ref={register({
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                  />
                </div>
                {errors.salary && (
                  <span className="is-danger">{errors.salary.message}</span>
                )}
              </InfoWrapperInput>
            </>
          )}

          <InfoWrapperInput title="Perks" optional="true">
            <div className="wrap-input">
              <textarea
                className="textarea"
                id="perks"
                name="perks"
                placeholder="Enter Perks "
                ref={register()}
              />
            </div>
            {errors.perks && (
              <span className="is-danger">{errors.perks.message}</span>
            )}
          </InfoWrapperInput>

          <InfoWrapperInput title="No. of Openings">
            <div className="wrap-input">
              <input
                className="input"
                id="openings"
                name="openings"
                placeholder="Enter your openings"
                defaultValue="10"
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
            </div>
            {errors.openings && (
              <span className="is-danger">{errors.openings.message}</span>
            )}
          </InfoWrapperInput>

          <InfoWrapperInput title="Skills requried">
            <div className="wrap-input">
              <textarea
                className="textarea"
                id="skills"
                name="skills"
                placeholder="Enter skills required"
                defaultValue="UX UI HTML"
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
            </div>
            {errors.skills && (
              <span className="is-danger">{errors.skills.message}</span>
            )}
          </InfoWrapperInput>

          <div className="flex justify-content-end">
            <Link to="/admin/posts">
              <button className="cancel-btn m-0 p-1">Cancel</button>
            </Link>
            <button
              className="submit-btn m-0 p-1"
              type="submit"
              disabled={disableBtn}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewJobPost;
