import React from "react";
import Header from "components/Header";
import Sidebar from "components/Sidebar";
import "./Dashboard.css";

const Dashboard = ({ children }) => {
  // const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  // if (isLoggedIn === false) {
  //     return <Redirect to="/login" />;
  // }
  return (
    <div className="dashboard">
      <Header />
      <div className="height-80"></div>

      {/* <div className="dashboard-view">
                <aside className="sidemenu">
                    <Sidebar />
                </aside>
                <main className="main-dashboard-area">{children}</main>
            </div> */}

      {/* <div className="dashboard-view"> */}
      <aside className="sidemenu">
        <Sidebar />
      </aside>
      {/* <div /> */}
      <main className="main-display-dashboard">
        <div className="main-dashboard-area">{children}</div>
      </main>
      {/* </div> */}
    </div>
  );
};

export default Dashboard;
