import React from "react";
import { Switch } from "react-router-dom";

// import Protected routes
import AuthenticatedRoute from "./AuthenticatedRoute";
import AuthenticatedDashboardRoute from "./AuthenticatedDashboardRoute";
import AuthDashboardAdminRoute from "./AuthDashboardAdminRoutes";
import UnAuthenticatedRoute from "./UnAuthenticatedRoute";
import MainRoute from "./MainRoute";
// import main layout views
import Job from "views/Jobs";
import JobDetails from "views/JobDetails";

// import dashboard Pages

// import Auth views
import Login from "views/auth/Login";
import Register from "views/auth/Register";
import RegisterEmployer from "views/auth/RegisterEmployer";
import ForgotPassword from "views/auth/ForgotPassword";

// import dashboard views
import AddInfo from "views/auth/AddRegisterInfo";
import UserProfile from "views/UserProfile";
import UserProfileEdit from "views/UserProfileEdit";
import Post from "views/dashboard/job/ViewJobPosts";
import PostDetails from "views/dashboard/job/JobPost";
import PostEdit from "views/dashboard/job/EditJobPost";
import NewPost from "views/dashboard/job/NewJobPost";
import AdminUsers from "views/dashboard/AdminUsers";
import Dashboard from "views/dashboard/Dashboard";
import Categories from "views/dashboard/Category";
import Courses from "views/dashboard/course/Courses";
import CourseEdit from "views/dashboard/course/CourseEdit";
import AddNewCoursePageOne from "views/dashboard/course/AddNewCoursePageOne";
import AddNewCoursePageTwo from "views/dashboard/course/AddNewCoursePageTwo";
import AddNewCoursePageThree from "views/dashboard/course/AddNewCoursePageThree";

// NotFound
import NotFound from "views/NotFound";

const Main = () => {
  return (
    <React.Fragment>
      <Switch>
        <AuthenticatedDashboardRoute
          path="/admin"
          exact
          component={Dashboard}
        />

        <AuthenticatedDashboardRoute
          path="/admin/posts/:id"
          component={PostDetails}
        />
        <AuthenticatedDashboardRoute path="/admin/posts" component={Post} />

        <AuthenticatedDashboardRoute
          path="/admin/editpost/:id"
          component={PostEdit}
        />

        <AuthenticatedDashboardRoute
          path="/admin/newpost"
          component={NewPost}
        />

        <AuthDashboardAdminRoute
          path="/admin/users/:id"
          component={AdminUsers}
        />
        <AuthDashboardAdminRoute path="/admin/users" component={AdminUsers} />
        <AuthDashboardAdminRoute
          path="/admin/categories"
          component={Categories}
        />
        <AuthDashboardAdminRoute
          path="/admin/courses/:id"
          component={CourseEdit}
        />
        <AuthDashboardAdminRoute path="/admin/courses" component={Courses} />

        <AuthDashboardAdminRoute
          path="/admin/new-course"
          component={AddNewCoursePageOne}
        />
        <AuthDashboardAdminRoute
          path="/admin/new-course-media"
          component={AddNewCoursePageTwo}
        />
        <AuthDashboardAdminRoute
          path="/admin/new-course-pricing"
          component={AddNewCoursePageThree}
        />

        <MainRoute exact path="/" component={Job} />
        <MainRoute exact path="/details/:id" component={JobDetails} />
        <UnAuthenticatedRoute path="/login" component={Login} />
        <UnAuthenticatedRoute
          path="/registration/student"
          component={Register}
        />
        <UnAuthenticatedRoute
          path="/registration/employer"
          component={RegisterEmployer}
        />
        <UnAuthenticatedRoute path="/forgot" component={ForgotPassword} />
        <MainRoute path="/addinfo" component={AddInfo} />
        <AuthenticatedRoute path="/profile" component={UserProfile} />
        <AuthenticatedRoute path="/editprofile" component={UserProfileEdit} />
        {/* TODO: Reset password and forgot psssword has to be implemented */}
        {/* <Route path="/reset/:token" component={ResetPassword} /> */}
        {/* <Route path="/forgotpassword" component={ForgotPassword} /> */}
        {/* Posts and categories  paths*/}
        {/* <Route exact path="/" component={Home} />
              <Route path="/posts" component={Posts} />
              <Route path="/contact" component={Contact} />
              <Route path="/donate" component={Donate} />
              <Route path="/post" component={SinglePost} />
              <Route path="/videos" component={Videos} /> */}
        {/* <AuthenticatedRoute
                path="/donationdetails"
                component={DonateDetails}
              />
              <AuthenticatedRoute
                path="/donationhistory"
                component={DonationHistory}
              />
              <AuthenticatedRoute
                path="/successfuldonation"
                component={SuccessfulDonation}
              />
              <AuthenticatedRoute path="/userprofile" component={UserProfile} /> */}

        <MainRoute path="*" component={NotFound} />
      </Switch>
    </React.Fragment>
  );
};

export default Main;
