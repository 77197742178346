import { Fragment, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { cityDataList } from "utils/constants";
import Modal from "components/elements/ModalWrapper";
import InfoWrapper from "components/elements/InfoWrapperInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const FilterModal = ({
  setShowModal,
  showModal,
  category,
  loc,
  setLoc,
  setCat,
  fetch,
  cat,
  setDisplayTitle,
}) => {
  const { register, handleSubmit, errors } = useForm();
  const modalRef = useRef();
  const [isNewCat, setNewCat] = useState(cat);
  const [isNewLoc, setNewLoc] = useState(loc);
  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(true);
    }
  };

  const onSectionSubmit = async (data) => {
    try {
      console.log(data);
      //    await mutation.mutate({ data });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Fragment>
      <div className="modal-wrapper" ref={modalRef} onClick={closeModal}>
        <div className="modal-container flex-column justify-content-around align-items-center p-2">
          <h4 className="mb-1">Filter</h4>
          {/* <div className="filter">
                        <h1 className="mb-1">Filters</h1> */}
          <div className="mb-1">
            <h4>Categories</h4>
            <select
              className="classic"
              value={isNewCat}
              onChange={(e) => setNewCat(e.target.value)}
            >
              <option value="">Select Category</option>
              {category &&
                category.results.map((c) => (
                  <option key={c._id} value={c.title}>
                    {c.title}
                  </option>
                ))}
            </select>
          </div>
          <div className="">
            <h4>Location</h4>
            <select
              className="classic"
              value={isNewLoc}
              onChange={(e) => setNewLoc(e.target.value)}
            >
              <option value="">Select Location</option>
              {cityDataList.map((l) => (
                <option value={l} key={l}>
                  {l}
                </option>
              ))}
            </select>
          </div>
          <button
            className="submit-btn mt-1 mb-1"
            onClick={() => {
              setCat(isNewCat);
              setLoc(isNewLoc);
              setDisplayTitle(isNewCat);
              setShowModal(true);
            }}
          >
            Apply
          </button>
          {/* </div> */}
          <div
            className="cancel-btn"
            onClick={() => {
              setShowModal(true);
            }}
          >
            Cancel
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FilterModal;
