import { useMutation, useQuery, useQueryClient } from "react-query";
import { getCourse, deleteCourse } from "queries/courseQueries";
import { Link } from "react-router-dom";
import NewCourseModal from "views/modals/NewCourseModal";
import { useState } from "react";
import Pagination from "helpers/Pagination";
import DeleteModal from "views/modals/DeleteModal";

const Courses = () => {
  const [deleteModalIsHidden, setDeleteModalIsHidden] = useState(true);
  const [mutationQueries, setMutationQueries] = useState();
  const [modalData, setModalData] = useState();
  const cour = useQuery(["courses"], getCourse);
  const deleteCourseFunction = (value) => {
    const id = value;
    setModalData(id);
    setMutationQueries("course");
    setDeleteModalIsHidden(false);
  };

  const convertSpaceToHyphen = (str) => {
    const val = str.replace(/\s+/g, "-").toLowerCase();
    return val;
  };

  return (
    <div className="course">
      {deleteModalIsHidden ? null : (
        <DeleteModal
          setShowModal={setDeleteModalIsHidden}
          showModal={deleteModalIsHidden}
          modalData={modalData}
          setModalData={setModalData}
          mutationQueries={mutationQueries}
          setMutationQueries={setMutationQueries}
        />
      )}
      <div className="flex justify-space-between p-2">
        <h1 className="dashboard-title">Courses</h1>
        <Link className="submit-btn" to="/admin/new-course">
          +&nbsp;&nbsp; New Course
        </Link>
        {/* </Link> */}
      </div>
      <div className="flex ">
        {cour.isLoading ? (
          <div>Loading...</div>
        ) : cour.isError ? (
          <div>{cour.error.message}</div>
        ) : cour.data && cour.data.totalCount === 0 ? (
          <span className="m-auto">
            <strong>There is no Course to display</strong>
          </span>
        ) : (
          <div className="dashboard-table">
            <table className="table table-bordere">
              <thead>
                <tr>
                  {/* <th scope="col">UID</th> */}
                  <th scope="col">Title</th>
                  <th scope="col">Published</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {cour.data &&
                  cour.data.results.map((course) => (
                    <tr key={course._id}>
                      {/* {logging(course.createdAt)} */}
                      {/* <th scope="row">1</th> */}
                      <td>{course.title}</td>
                      <td>{course.isActive ? "Yes" : "Draft"}</td>
                      <td>
                        <Link
                          to={{
                            pathname: `/admin/courses/${convertSpaceToHyphen(
                              course.title
                            )}-${course._id}`,
                            course,
                          }}
                        >
                          <button className="submit-btn">Edit Course</button>
                        </Link>
                      </td>
                      <td>
                        <Link>
                          <button
                            className="cancel-btn"
                            onClick={() => {
                              deleteCourseFunction(course._id);
                            }}
                          >
                            Delete Course
                          </button>
                        </Link>{" "}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {/* {cour && ( */}
            {/* <Pagination items={cour.data} setPage={setPage} page={page} /> */}
            {/* )} */}
          </div>
        )}

        {/* <div className="course-card ml-1"> */}
        {/*   <img className="course-img" src="" alt="course img" /> */}
        {/*   <p className="p-1 pb-2">{title}</p> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default Courses;
