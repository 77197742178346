import React, { useState } from "react";
import Filter from "components/Filter";
import JobsDisplay from "components/JobsDisplay";
import "./Jobs.css";
import { useQuery, useQueryClient } from "react-query";
import { getPosts } from "queries/postsQueries";
import { getCompleteCategory } from "queries/categoryQueries";
import FilterModal from "./modals/FilterModal";
import Pagination from "helpers/Pagination";
import Spinner from "components/elements/Spinner";

const Jobs = () => {
  const [filterModalIsHidden, setFilterModalIsHidden] = React.useState(true);

  const [page, setPage] = React.useState(1);
  const [cat, setCat] = React.useState("");
  const [loc, setLoc] = React.useState("");
  const [shouldFetching, setShouldFetching] = useState(false);
  const [displayTitle, setDisplayTitle] = React.useState("");
  const isActive = true;
  const queryClient = useQueryClient();

  const res0 = useQuery(
    ["posts", page, isActive, cat, loc],
    () => getPosts(page, isActive, cat, loc),
    {
      keepPreviousData: true,
    }
  );
  const res1 = useQuery("categories", getCompleteCategory);

  if (res0.isLoading && res1.isLoading) {
    return <Spinner />;
  }
  if (res0.isError || res1.isError) {
    return <span>Error</span>;
  }
  return (
    <div className="main-container">
      <button
        className={filterModalIsHidden ? "jobs-filter-btn" : "none"}
        onClick={() => {
          setFilterModalIsHidden(false);
        }}
      >
        <i className="fas fa-filter"></i>
      </button>
      {filterModalIsHidden ? null : (
        <FilterModal
          setShowModal={setFilterModalIsHidden}
          showModal={filterModalIsHidden}
          category={res1.data}
          setCat={setCat}
          loc={loc}
          setLoc={setLoc}
          fetch={res0}
          cat={cat}
          setDisplayTitle={setDisplayTitle}
        />
      )}
      <aside className="sidemenu-jobs">
        <Filter
          category={res1.data}
          setCat={setCat}
          setLoc={setLoc}
          loc={loc}
          fetch={res0}
          cat={cat}
          setDisplayTitle={setDisplayTitle}
        />
      </aside>
      <div />
      <main className="main-display">
        {res0.data &&
          (res0.data.totalPage !== 0 ? (
            <>
              <JobsDisplay
                posts={res0.data}
                page={page}
                setPage={setPage}
                cat={displayTitle}
                isFetching={res0.isFetching}
                isLoading={res0.isLoading}
              />
              <Pagination items={res0.data} page={page} setPage={setPage} />
            </>
          ) : (
            <div>No Job Posts found</div>
          ))}
        {/* {res0.isFetching ? <span> Loading...</span> : null} */}
      </main>
    </div>
  );
};

export default Jobs;
