import { Link, Redirect } from "react-router-dom";

import "./Login.css";
import Card from "components/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";

import { authSignUp } from "queries/authQueries";
import { saveToken, saveUser } from "helpers/storageHelper";

import { useMutation, useQueryClient } from "react-query";
import useErrorStore from "stores/ErrorStore";
import useDataStore from "stores/DataStore";

const Register = () => {
  const queryClient = useQueryClient();
  const message = useErrorStore((state) => state.message);
  const role = "user";
  const addRedirectData = useDataStore((state) => state.addRedirectData);
  const mutation = useMutation(authSignUp, {
    onSuccess: (data) => {
      // saveToken(data.accessToken, data.refreshToken);
      // saveUser(data.user);
      addRedirectData("success");
    },
  });

  const { register, handleSubmit, errors } = useForm();
  const onSubmit = async (data) => {
    try {
      console.log(data);
      console.log(role);
      await mutation.mutate({ data, role });
    } catch (error) {
      console.log(error);
    }
  };
  if (mutation.isSuccess) {
    return <Redirect to="/login" />;
  }
  return (
    <Card name="Register">
      {mutation.isLoading ? (
        "Loading"
      ) : (
        <>
          {mutation.isError ? (
            <div className="is-danger">An error occurred: {message}</div>
          ) : null}
          {mutation.isSuccess ? <div>Logged in</div> : null}
          <form
            className="validate-form min-wid-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* {isLoading ? <div>Loading.......</div> : null} */}

            <div className="flex-column">
              <div className="wrap-input ">
                <label className="mb-0">First Name</label>
                <input
                  className="input-half mr-40"
                  id="firstName"
                  name="firstName"
                  placeholder="Enter your first name"
                  ref={register({
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                />
              </div>
              {errors.firstName && (
                <span className="is-danger">{errors.firstName.message}</span>
              )}
            </div>
            <div className="flex-column">
              <div className="wrap-input">
                <label className="mb-0">Last Name</label>
                <input
                  className="input-half"
                  id="lastName"
                  name="lastName"
                  placeholder="Enter your last name"
                  ref={register({
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                />
              </div>
              {errors.lastName && (
                <span className="is-danger">{errors.lastName.message}</span>
              )}
            </div>

            <div className="wrap-input">
              <label className="mb-0">Email Id</label>
              <input
                className="input"
                id="email"
                name="email"
                placeholder="Enter your email"
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Enter a valid email",
                  },
                })}
              />
            </div>
            {errors.email && (
              <span className="is-danger">{errors.email.message}</span>
            )}
            <div className="wrap-input">
              <label className="mb-0">Password</label>
              <input
                className="input"
                id="password"
                type="password"
                name="password"
                placeholder="Minimum 8 Characters"
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                  minLength: {
                    value: 8,
                    message: "Minimum Length is 8",
                  },
                })}
              />
              <span className="symbol-input">
                <i
                  /* className="fa fa-lock" */
                  aria-hidden="true"
                ></i>
              </span>
            </div>
            {errors.password && (
              <span className="is-danger">{errors.password.message}</span>
            )}
            {/* {isError ? ( */}
            {/* <div className="is-danger">
                         {error.message},{message}
                    </div> */}
            {/* ) : null} */}
            <div className="flex-column align-items-center">
              <button className="button-blue" type="submit">
                Register
              </button>

              <div className="text-center p-t-136">
                <Link className="forgot-password-text-color" to="/login">
                  Already have an account?
                  <i
                    className="fa fa-long-arrow-right m-l-5"
                    aria-hidden="true"
                  ></i>
                </Link>
              </div>
            </div>
          </form>
        </>
      )}
    </Card>
  );
};

export default Register;
