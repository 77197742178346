import "./Header.css";
import { logout } from "helpers/storageHelper";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import "./Button.css";

import Dropdown from "./Dropdown";
import useAuthStore from "stores/AuthStore";
import useUserStore from "stores/UserStore";

const Header = () => {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const name = useUserStore((state) => state.name);
  const role = useUserStore((state) => state.role);

  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  const Header_Data = {
    user: (
      <Fragment>
        <li className="nav-item">
          <Link to="/" className="nav-links" onClick={closeMobileMenu}>
            Jobs
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/addinfo" className="nav-links" onClick={closeMobileMenu}>
            {name}
          </Link>
        </li>
        <li className="nav-item">
          <button className="btn mh-1" onClick={logout}>
            Logout
          </button>
        </li>
      </Fragment>
    ),
    employer: (
      <Fragment>
        <li className="nav-item">
          <Link
            to="/admin/posts"
            className="nav-links"
            onClick={closeMobileMenu}
          >
            Dashboard
          </Link>
        </li>{" "}
        <li className="nav-item">
          <Link to="/addinfo" className="nav-links" onClick={closeMobileMenu}>
            {name}
          </Link>
        </li>
        <li className="nav-item">
          <button className="btn mh-1" onClick={logout}>
            Logout
          </button>
        </li>
      </Fragment>
    ),
    admin: (
      <Fragment>
        <li className="nav-item">
          <Link
            to="/admin/posts"
            className="nav-links"
            onClick={closeMobileMenu}
          >
            Dashboard
          </Link>
        </li>{" "}
        <li className="nav-item">
          <Link to="/addinfo" className="nav-links" onClick={closeMobileMenu}>
            {name}
          </Link>
        </li>
        <li className="nav-item">
          <button className="btn mh-1" onClick={logout}>
            Logout
          </button>
        </li>
      </Fragment>
    ),
    default: (
      <Fragment>
        <li className="nav-item">
          <Link to="/" className="nav-links" onClick={closeMobileMenu}>
            Jobs
          </Link>
        </li>{" "}
        <li className="nav-item-2 mobile-only">
          <Link to="/login" className="nav-links-2" onClick={closeMobileMenu}>
            Login
          </Link>
        </li>
        <li className="nav-item-2 mobile-only">
          <Link
            to="/registration/student"
            className="nav-links-2"
            onClick={closeMobileMenu}
          >
            Register As a Student
          </Link>
        </li>
        <li className="nav-item-2 mobile-only">
          <Link
            className="nav-links-2"
            to="/registration/employer"
            onClick={closeMobileMenu}
          >
            Register As an Employer
          </Link>
        </li>
        <li className="nav-item-2 desktop-only">
          <Link to="/login" className="nav-links-2" onClick={closeMobileMenu}>
            <button className="btn-outline">
              <strong>Login</strong>
            </button>{" "}
          </Link>
        </li>
        <li
          className="nav-item-2 desktop-only"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <button className="btn">
            <strong>Register</strong>
            <i className="ml-0 fas fa-caret-down" />
          </button>
          {dropdown && <Dropdown />}
        </li>
      </Fragment>
    ),
  };

  const HeaderEnum = ({ status }) => {
    return <Fragment>{Header_Data[status]}</Fragment>;
  };
  return (
    <>
      <nav className="navbar">
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"} />
        </div>
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          App Logo
          {/* <i class="fab fa-firstdraft" /> */}
        </Link>
        {isLoggedIn ? (
          <div></div>
        ) : (
          <ul className="menu-icon">
            <li
              className="nav-item-2"
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <button className="btn">
                <strong>Register</strong>
                <i className="ml-0 fas fa-caret-down" />
              </button>
              {dropdown && <Dropdown />}
            </li>
          </ul>
        )}
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          {isLoggedIn ? (
            <HeaderEnum status={role} />
          ) : (
            <HeaderEnum status="default" />
          )}
        </ul>
        {/* <Button />
                <Button /> */}
      </nav>
    </>
  );
};
export default Header;
