import "./Card.css";

const Card = ({ children, name }) => (
  <div className="card-container">
    <div className="card">
      <h1>{name}</h1>
      {children}
    </div>
  </div>
);

export default Card;
