import React, { useState } from "react";
import { useParams } from "react-router-dom";
import CourseCurriculum from "./curriculum/CourseCurriculum";
import CourseDetails from "./EditCourseDetails";
import Media from "./Media";
import Pricing from "./Pricing";
import "./CourseEdit.css";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getCourseById, publishCourse } from "queries/courseQueries";
import { queries } from "@testing-library/react";

const CourseEdit = (props) => {
  const { id } = useParams();
  const getLastValueOfId = (id) => {
    const newId = id.split("-");
    return newId[newId.length - 1];
  };
  const course_id = getLastValueOfId(id);
  const [isActive, setActive] = useState(0);
  const queryClient = useQueryClient();
  const segmentDataArray = ["Curriculum", "Course Details", "Media", "Pricing"];
  const cour = useQuery(["courses", course_id], () => getCourseById(course_id));
  const {
    isLoading,
    isError,
    data: curriculumData,
    error,
  } = useQuery(["curriculum", course_id], () => getCurriculum(course_id));
  const mutation = useMutation(publishCourse, {
    onSuccess: () => {
      queryClient.invalidateQueries("courses");
    },
  });
  const TotalLecturecountFunction = () => {
    let total = 0;
    curriculumData.sections.map(
      (element) => (total = total + element.lectures.length)
    );

    return total;
  };

  const onClickHandler = () => {
    const data = TotalLecturecountFunction();
    if (data > 0) {
      mutation.mutate(course_id);
      return;
    }
    alert("Should have atleast one lecture in a course");
  };
  return (
    <div className="course">
      <div className="flex justify-space-between p-2">
        <h1 className="dashboard-title">Courses Manager</h1>
      </div>
      <div className="flex  pl-2 pb-2 pr-2 align-items-center justify-space-between">
        <div className="segmented-view flex">
          {segmentDataArray.map((value, index) => (
            <div
              className={
                isActive === index ? "segmented-item active" : "segmented-item"
              }
              key={index}
              onClick={() => setActive(index)}
            >
              <h2>{value}</h2>
            </div>
          ))}
        </div>
        {cour.data && cour.data.isActive ? null : (
          <div className="submit-btn" onClick={onClickHandler}>
            Publish Course
          </div>
        )}
      </div>

      {isActive === 0 ? (
        <CourseCurriculum course_id={course_id} />
      ) : isActive === 1 ? (
        <CourseDetails course_id={course_id} cour={cour} />
      ) : isActive === 2 ? (
        <Media cour={cour} />
      ) : (
        <Pricing cour={cour} />
      )}
    </div>
  );
};

export default CourseEdit;
