import React, { useState } from "react";
import "./AddNewCoursePage.css";
import { useForm, useFieldArray } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams, useHistory, Redirect } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import useErrorStore from "stores/ErrorStore";
import InfoWrapperInput from "components/elements/InfoWrapperInput";
import { getCategory } from "queries/categoryQueries";
import { createCourse } from "queries/courseQueries";

const AddNewCoursePageTwo = () => {
  let history = useHistory();
  const queryClient = useQueryClient();
  const { register, handleSubmit, errors, getValues } = useForm();

  const [disableBtn, setDisableBtn] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [isDiscount, setDiscount] = useState();
  const [image, setImage] = React.useState({ preview: "", raw: "" });

  const handleChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image.raw);

    await fetch("YOUR_URL", {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: formData,
    });
  };

  const mutation = useMutation(createCourse, {
    onSuccess: () => {
      queryClient.invalidateQueries("courses");
      setDisableBtn(false);
      history.push("/admin/courses");
    },
    onError: () => {
      setDisableBtn(false);
    },
  });

  const onSubmit = (data) => {
    console.log(data);
  };
  const valueIsFree = getValues("isFree");
  const valueIsDiscount = getValues("isDiscount");
  return (
    <main>
      <section>
        <div className="step-wrapper m-2">
          <div className="step blue-step" />
          <div className="step blue-step" />
          <div className="step blue-step" />
        </div>
        <h2 align="center">Pricing </h2>
        <p align="center">Lorem Ipsum test content</p>
      </section>
      <section className="course-form-wrapper">
        <form className="info-viewer mt-2" onSubmit={handleSubmit(onSubmit)}>
          <InfoWrapperInput title="Course Price(₹)">
            <label>
              <input
                type="checkbox"
                id="isFree"
                name="isFree"
                ref={register()}
                defaultChecked={isChecked}
                onChange={() => setChecked(!isChecked)}
              />
              &nbsp; &nbsp; Is this course free?
            </label>
            <div className="wrap-input">
              <input
                className="input"
                id="pricing"
                name="pricing"
                placeholder="Enter your course price"
                type="number"
                ref={register({
                  validate: {
                    required: (value) => {
                      if (!value && !isChecked) return "This field is required";
                      return true;
                    },
                  },
                })}
                disabled={isChecked}
              />
            </div>
            {errors.pricing && !isChecked && (
              <span className="is-danger">{errors.pricing.message}</span>
            )}
          </InfoWrapperInput>
          {!isChecked && (
            <InfoWrapperInput title="Course Discount(₹)">
              <label>
                <input
                  type="checkbox"
                  id="isDiscount"
                  name="isDiscount"
                  ref={register()}
                  defaultChecked={isDiscount}
                  onChange={() => setDiscount(!isDiscount)}
                />
                &nbsp; &nbsp; Does this course have a discount?
              </label>
              <div className="wrap-input">
                <input
                  className="input"
                  id="discount"
                  name="discount"
                  placeholder="Enter your course discount"
                  type="number"
                  ref={register({
                    validate: {
                      required: (value) => {
                        if (!value && isDiscount)
                          return "This field is required";
                        return true;
                      },
                    },
                  })}
                  disabled={!isDiscount}
                />
              </div>
              {errors.discount && isDiscount && (
                <span className="is-danger">{errors.discount.message}</span>
              )}
            </InfoWrapperInput>
          )}
          <div className="flex justify-content-end">
            <Link to="/admin/courses">
              <button className="cancel-btn m-0 p-1">Cancel</button>
            </Link>
            <button
              className="submit-btn m-0 p-1"
              type="submit"
              disabled={disableBtn}
            >
              Save
            </button>
          </div>
        </form>
      </section>
    </main>
  );
};

export default AddNewCoursePageTwo;
