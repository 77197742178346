import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUser,
  faEnvelope,
  faLock,
  faEye,
  faEyeSlash,
  faArrowUp,
  faArrowDown,
  faExpand,
  faCompress,
  faBolt,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

// import {
//     faFacebookSquare,
//     faTwitter,
//     faLinkedin,
//     faSkype,
//     faGoogle,
//     faInstagramSquare,
//     faYoutube,
// } from "@fortawesome/free-brands-svg-icons";

library.add(
  faUser,
  faEnvelope,
  faLock,
  faEye,
  faEyeSlash,
  faArrowUp,
  faArrowDown,
  faExpand,
  faCompress,
  faBolt,
  faSpinner
  // faFacebookSquare,
  // faTwitter,
  // faLinkedin,
  // faSkype,
  // faGoogle,
  // faInstagramSquare,
  // faYoutube
);
