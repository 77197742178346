import "./InfoWrapper.css";

const InfoWrapperInput = ({ title, children, optional }) => (
  <div className="info-wrapper-input info-wrapper">
    <div className="info-wrapper-item mb-1 mr-1 align-self-center">
      <strong>{title}</strong>
      {optional === "true" && <span> (optional)</span>}
    </div>
    <div className="info-wrapper-item">{children}</div>
  </div>
);

export default InfoWrapperInput;
