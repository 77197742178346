import React, { useState, useRef, useEffect } from "react";
import Section from "components/elements/Section";
import Lecture from "components/elements/Lecture";
import "views/dashboard/course/CourseEdit.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// comment
// This code was copied from the following link
// https://codesandbox.io/s/zqwz5n5p9x?file=/src/index.js
// be very careful in changing any part of it as it may break.

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// const QuoteItem = styled.div`
//   width: 200px;
//   border: 1px solid grey;
//   margin-bottom: ${grid}px;
//   background-color: lightblue;
//   padding: ${grid}px;
// `;

function Quote({ quote, index, isLessons }) {
  return (
    <Draggable draggableId={quote._id} index={index}>
      {(provided) => (
        <div
          className="sort-view"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {isLessons
            ? `${quote.lectureType}: ${quote.lectureTitle} `
            : quote.sectionTitle}
        </div>
      )}
    </Draggable>
  );
}

const QuoteList = React.memo(function QuoteList({ quotes, isLessons }) {
  return quotes.map((quote, index) => (
    <Quote quote={quote} index={index} key={quote._id} isLessons={isLessons} />
  ));
});

function DragAndDrop({
  data,
  isLessons,
  curriculumData,
  sectionIndex,
  setTemperaryCurriculumData,
}) {
  const [state, setState] = useState({ quotes: data });

  const consolidation = (quotes) => {
    console.log("consalidaion quoets and data is ");
    console.log(quotes);
    console.log(data);
    let updatedData = data.map((item, index) => {
      if (index === sectionIndex) {
        return { ...item, lectures: quotes };
      }
      return item;
    });
    console.log("Updated data from Drag and drop");
    console.log(updatedData);
    setTemperaryCurriculumData(updatedData);
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const quotes = reorder(
      state.quotes,
      result.source.index,
      result.destination.index
    );

    setState({ quotes });
    // temperaryCurriculumData = quotes;
    setTemperaryCurriculumData(quotes);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <QuoteList quotes={state.quotes} isLessons={isLessons} />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DragAndDrop;
