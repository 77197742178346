import React from 'react'
import DragAndDrop from "components/DragAndDrop";
import Modal from "components/elements/ModalWrapper";
import AddLectureModal from "views/modals/AddLectureModal";
import AddSectionModal from "views/modals/AddSectionModal";
import "../CourseEdit.css";

const CurriculumModals = (props) => {
  const { value } = props;

  return (
    <>
      {value.sectionIsHidden ? null : (
        <AddSectionModal
          setShowModal={value.setSectionIsHidden}
          showModal={value.sectionIsHidden}
          onSectionSubmit={value.onSectionSubmit}
          register={value.register}
          handleSubmit={value.handleSubmit}
          errors={value.errors}
          modalRef={value.modalRef}
          isEdit={value.isEdit}
          editData={value.editData}
          setEdit={value.setEdit}
          setEditData={value.setEditData}
        />
      )}
      {value.lectureIsHidden ? null : (
        <AddLectureModal
          setShowModal={value.setLectureIsHidden}
          showModal={value.lectureIsHidden}
          onLectureSubmit={value.onLectureSubmit}
          register={value.register}
          handleSubmit={value.handleSubmit}
          errors={value.errors}
          modalRef={value.modalRef}
          isEdit={value.isEdit}
          editData={value.editData}
          setEdit={value.setEdit}
          setEditData={value.setEditData}
          temperaryData={value.temperaryData}
          setTemperaryData={value.setTemperaryData}
        />
      )}
      {value.sortIsHidden ? null : (
        <Modal
          title="Sort Section"
          setShowModal={value.setSortIsHidden}
          showModal={value.sortIsHidden}
        >
          <DragAndDrop
            data={value.curriculumData.sections}
            setTemperaryCurriculumData={value.setTemperaryCurriculumData}
            curriculumData={value.curriculumData.content}
            temperaryCurriculumData={value.temperaryCurriculumData}
          />
          <div
            className="submit-btn inline-block mb-1 mt-1"
            onClick={value.onSortSave}
          >
            Save
          </div>
          <div
            className="cancel-btn"
            onClick={() => {
              value.setSortIsHidden(true);
            }}
          >
            Cancel
          </div>
        </Modal>
      )}
      {value.lessonSortIsHidden ? null : (
        <Modal
          title="Sort Lessons"
          setShowModal={value.setLessonSortIsHidden}
          showModal={value.lessonSortIsHidden}
        >
          <DragAndDrop
            data={value.curriculumData.sections[value.sortLessonValue].lectures}
            isLessons
            setTemperaryCurriculumData={value.setTemperaryCurriculumData}
            curriculumData={value.curriculumData}
            sectionIndex={value.sortLessonValue}
          />

          <div
            className="submit-btn inline-block mb-1 mt-1"
            onClick={value.onLessonSortSave}
          >
            Save
          </div>
          <div
            className="cancel-btn"
            onClick={() => {
              value.setLessonSortIsHidden(true);
            }}
          >
            Cancel
          </div>
        </Modal>
      )}
    </>
  );
};

export default CurriculumModals;
