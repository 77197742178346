import { Fragment, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  deleteSkills,
  deleteHigherSecondaryEducation,
  deleteSecondaryEducation,
  deleteEducation,
  deleteCertificates,
} from "queries/userQueries";
import { deleteCourse } from "queries/courseQueries";
import { successToast } from "helpers/Toast";

const DeleteModal = ({
  setShowModal,
  modalData,
  setModalData,
  setMutationQueries,
  mutationQueries,
}) => {
  const modalRef = useRef();
  const queryClient = useQueryClient();
  const switchValue = (value) => {
    switch (value) {
      case "secondary":
        return deleteSecondaryEducation;
      case "higher":
        return deleteHigherSecondaryEducation;
      case "education":
        return deleteEducation;
      case "certificates":
        return deleteCertificates;
      case "skills":
        return deleteSkills;
      case "course":
        return deleteCourse;
      default:
        return null;
    }
  };
  const switchInvalidateQueries = (value) => {
    switch (value) {
      case "secondary":
      case "higher":
      case "education":
      case "certificates":
      case "skills":
        return "userInfo";
      case "course":
        return "courses";
      default:
        return null;
    }
  };

  const resetValues = () => {
    setModalData();
    setMutationQueries();
    setShowModal(true);
  };

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      resetValues();
    }
  };
  const mutation = useMutation(switchValue(mutationQueries), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(switchInvalidateQueries(mutationQueries));
      successToast(data.message);
      resetValues();
    },
    onError: () => {
      console.log("error");
      resetValues();
    },
  });

  const onSubmit = () => {
    mutation.mutate(modalData);
  };

  return (
    <Fragment>
      <div className="modal-wrapper" ref={modalRef} onClick={closeModal}>
        <div className="delete-modal-container flex-column justify-content-around align-items-center p-2">
          <p>Are you sure you want to delete? </p>
          <div className="flex align-items-center justify-space-around mt-1">
            <div
              className="btn-outline mr-1"
              onClick={() => {
                resetValues();
              }}
            >
              No
            </div>
            <div className="btn" onClick={onSubmit}>
              Yes
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default DeleteModal;
