import "./Jobs.css";
import JobPostDetails from "components/JobPostDetails";

const JobDetails = () => {
  return (
    <div className="main-container">
      <JobPostDetails />
    </div>
  );
};
export default JobDetails;
