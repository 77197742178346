import { useState } from "react";
import ReactPlayer from "react-player";
import InfoWrapperInput from "components/elements/InfoWrapperInput";
import { useForm, useFieldArray } from "react-hook-form";
import "views/dashboard/course/CourseEdit.css";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getCategory } from "queries/categoryQueries";
import {
  getCourseById,
  editCourseDetails,
  addLectureURL,
  addLectureQuizQuestions,
} from "queries/courseQueries";
import Spinner from "components/elements/Spinner";
import QuizContent from "./QuizContent";
const Lecture = (props) => {
  const { lecture, lectureValue, onEditClick, onDeleteClick } = props;
  const [isView, setView] = useState(false);

  const { register, handleSubmit, errors, watch, control, reset } = useForm();

  return (
    <div className="curriculum-lecture">
      <div className="flex justify-space-between align-items-center">
        <div>
          <span>
            {lecture.lectureType} {lectureValue} : {lecture.lectureTitle}
          </span>
          <span className="ml-0 align-items" onClick={() => setView(!isView)}>
            <i
              className={isView ? "fas fa-chevron-up" : "fas fa-chevron-down"}
            ></i>
          </span>
        </div>
        <div className="hidden">
          <button className="btn font-btn" onClick={() => onEditClick(lecture)}>
            <i className="far fa-edit"></i>
          </button>

          <button
            className="btn font-btn"
            onClick={() => onDeleteClick(lecture)}
          >
            <i className="far fa-trash-alt"></i>
          </button>
        </div>
      </div>
      {isView && (
        <div className="lecture-content mt-1">
          {lecture.lectureType === "lecture" ? (
            <LectureContent lecture={lecture} />
          ) : (
            <QuizContent lecture={lecture} />
          )}
        </div>
      )}
    </div>
  );
};

const LectureContent = (props) => {
  const { lecture } = props;
  console.log(lecture);
  const { register, handleSubmit, errors, watch, control, reset } = useForm();

  const [disableBtn, setDisableBtn] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const queryClient = useQueryClient();
  const mutation = useMutation(addLectureURL, {
    onSuccess: () => {
      queryClient.invalidateQueries("curriculum");
      setDisableBtn(false);
      setEdit(false);
    },
    onError: () => {
      setDisableBtn(false);
    },
  });
  const onSubmit = async (data) => {
    setDisableBtn(true);
    console.log("test data");
    try {
      console.log("add or edit Lecture Url ");
      console.log(data);
      const id = lecture._id;
      await mutation.mutate({ data, id });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="lecture-content">
      {lecture.url ? (
        isEdit ? (
          <form className="info-viewer mt-2" onSubmit={handleSubmit(onSubmit)}>
            <InfoWrapperInput title="Lecture URL">
              <div className="wrap-input">
                <input
                  className="input bg-lb"
                  id="url"
                  name="url"
                  placeholder="Enter your Lecture URL"
                  defaultValue={lecture.url}
                  ref={register({
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                />
              </div>
              {errors.url && (
                <span className="is-danger">{errors.url.message}</span>
              )}
            </InfoWrapperInput>
            <InfoWrapperInput title="Duration">
              <div className="wrap-input">
                <input
                  className="input bg-lb"
                  id="duration"
                  name="duration"
                  placeholder="hh:mm:ss"
                  defaultValue={lecture.duration}
                  ref={register({
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  })}
                />
              </div>
              {errors.duration && (
                <span className="is-danger">{errors.duration.message}</span>
              )}
            </InfoWrapperInput>

            <div className="flex justify-content-end">
              <button
                className="cancel-btn m-0 p-1"
                type="button"
                onClick={() => setEdit(false)}
              >
                Cancel
              </button>
              <button
                className="submit-btn m-0 p-1"
                type="submit"
                disabled={disableBtn}
              >
                Save
              </button>
            </div>
          </form>
        ) : (
          <div className="flex justify-space-between">
            <ReactPlayer
              className="react-player"
              url={lecture.url}
              controls={true}
              width="480px"
              height="360px"
            />
            <div>
              <button
                className="submit-btn m-0 p-1"
                type="submit"
                onClick={() => setEdit(true)}
              >
                Edit
              </button>
            </div>
          </div>
        )
      ) : (
        <form className="info-viewer mt-2" onSubmit={handleSubmit(onSubmit)}>
          <InfoWrapperInput title="Lecture URL">
            <div className="wrap-input">
              <input
                className="input bg-lb"
                id="url"
                name="url"
                placeholder="Enter your Lecture URL"
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
            </div>
            {errors.url && (
              <span className="is-danger">{errors.url.message}</span>
            )}
          </InfoWrapperInput>
          <InfoWrapperInput title="Duration">
            <div className="wrap-input">
              <input
                className="input bg-lb"
                id="duration"
                name="duration"
                placeholder="hh:mm:ss"
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
            </div>
            {errors.duration && (
              <span className="is-danger">{errors.duration.message}</span>
            )}
          </InfoWrapperInput>
          <div className="flex justify-content-end">
            <button
              className="submit-btn m-0 p-1"
              type="submit"
              disabled={disableBtn}
            >
              Save
            </button>
          </div>
        </form>
      )}

      {/* <div>
Add Video url
</div> */}
    </div>
  );
};

export default Lecture;
