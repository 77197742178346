import React, { useState } from "react";
import InfoWrapper from "components/elements/InfoWrapperInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useMutation, useQueryClient } from "react-query";
import "./Profile.css";
import { getUsersByID, editUserInfo } from "queries/adminQueries";
import { Link, Redirect, useHistory } from "react-router-dom";

import useErrorStore from "stores/ErrorStore";

import { useForm } from "react-hook-form";
import useUserStore from "stores/UserStore";

const ProfileEdit = () => {
  let history = useHistory();

  const role = useUserStore((state) => state.role);
  const [disableBtn, setDisableBtn] = useState(false);
  const queryClient = useQueryClient();
  const message = useErrorStore((state) => state.message);

  const { register, handleSubmit, errors } = useForm();

  const mutation = useMutation(editUserInfo, {
    onSuccess: () => {
      setDisableBtn(false);
      history.push("/profile");
      queryClient.invalidateQueries("userInfo");
    },
    onError: () => {
      setDisableBtn(false);
    },
  });
  const onSubmit = async (data) => {
    try {
      console.log(data);
      setDisableBtn(true);
      await mutation.mutate({ data });
    } catch (error) {
      console.log(error);
    }
  };
  const { isError, isLoading, data, error } = useQuery(
    "userInfo",
    getUsersByID
  );

  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }
  return (
    <div className="profile">
      <div className="flex justify-space-between p-2">
        <h1 className="dashboard-title">Edit Profile</h1>
        {/* <button className="submit-btn" type="submit" form="form1">
                    Save
                </button> */}
      </div>

      <div>
        <form className="info-viewer" onSubmit={handleSubmit(onSubmit)}>
          <InfoWrapper title="First Name">
            <div className="wrap-input">
              <input
                className="input capitalize"
                id="firstName"
                name="firstName"
                placeholder="Enter your firstName"
                defaultValue={data.firstName}
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
            </div>
            {errors.firstName && (
              <span className="is-danger">{errors.firstName.message}</span>
            )}
          </InfoWrapper>

          <InfoWrapper title="Last Name">
            <div className="wrap-input">
              <input
                className="input capitalize"
                id="lastName"
                name="lastName"
                placeholder="Enter your lastName"
                defaultValue={data.lastName}
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
            </div>
            {errors.lastName && (
              <span className="is-danger">{errors.lastName.message}</span>
            )}
          </InfoWrapper>

          <InfoWrapper title="Email">
            <div className="wrap-input">{data.email}</div>
          </InfoWrapper>
          {role === "user" ? (
            <InfoWrapper title="Bio">
              <div className="wrap-input">
                <textarea
                  className="textarea"
                  id="bio"
                  name="bio"
                  placeholder="Enter your bio"
                  defaultValue={data.bio}
                  ref={register()}
                />
              </div>
              {errors.bio && (
                <span className="is-danger">{errors.bio.message}</span>
              )}
            </InfoWrapper>
          ) : (
            <>
              <InfoWrapper title="Company Name">
                <div className="wrap-input">
                  <input
                    className="input uppercase"
                    id="companyName"
                    name="companyName"
                    placeholder="Enter your Company Name"
                    defaultValue={data.companyName}
                    ref={register({
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                  />
                </div>
                {errors.companyName && (
                  <span className="is-danger">
                    {errors.companyName.message}
                  </span>
                )}
              </InfoWrapper>

              <InfoWrapper title="Company Locotion">
                <div className="wrap-input">
                  <input
                    className="input capitalize"
                    id="companyLocation"
                    name="companyLocation"
                    placeholder="Enter your Company Locotion"
                    defaultValue={data.companyLocation}
                    ref={register({
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                  />
                </div>
                {errors.companyLocation && (
                  <span className="is-danger">
                    {errors.companyLocation.message}
                  </span>
                )}
              </InfoWrapper>

              <InfoWrapper title="Company Website">
                <div className="wrap-input">
                  <input
                    className="input capitalize"
                    id="website"
                    name="website"
                    placeholder="Enter your Company Locotion"
                    defaultValue={data.website}
                    ref={register()}
                  />
                </div>
                {errors.website && (
                  <span className="is-danger">{errors.website.message}</span>
                )}
              </InfoWrapper>

              <InfoWrapper title="GSTIN">
                <div className="wrap-input">
                  <input
                    className="input"
                    id="gstin"
                    name="gstin"
                    placeholder="Enter your gstin"
                    defaultValue={data.gstin}
                    ref={register()}
                  />
                </div>
                {errors.gstin && (
                  <span className="is-danger">{errors.gstin.message}</span>
                )}
              </InfoWrapper>

              <InfoWrapper title="About Company">
                <div className="wrap-input">
                  <textarea
                    className="textarea capitalize"
                    id="bio"
                    name="bio"
                    placeholder="Enter your Company Locotion"
                    defaultValue={data.bio}
                    ref={register({
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    })}
                  />
                </div>
                {errors.bio && (
                  <span className="is-danger">{errors.bio.message}</span>
                )}
              </InfoWrapper>
            </>
          )}
          <div className="flex justify-content-end">
            <Link to="/profile">
              <button className="cancel-btn m-0 p-1">Cancel</button>
            </Link>
            <button
              className="submit-btn m-0 p-1"
              type="submit"
              disabled={disableBtn}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ProfileEdit;
