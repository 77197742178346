import "./InfoWrapper.css";

const InfoWrapper = ({ title, description }) => (
  <div className="flex info-wrapper">
    <div className="info-wrapper-item mb-1 mr-1">
      <strong>{title}</strong>
    </div>
    <div className="info-wrapper-item">{description}</div>
  </div>
);

export default InfoWrapper;
