import React from "react";
import DashboardLayout from "layout/Dashboard";
import { Redirect, Route } from "react-router-dom";

import useAuthStore from "stores/AuthStore";
import useUserStore from "stores/UserStore";

const AuthenticatedDashboardRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const isRegistrationComplete = useUserStore(
    (state) => state.isRegistrationComplete
  );
  // const Auth_Data={

  //   use
  // }
  // const Auth_Enum
  const role = useUserStore((state) => state.role);
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          role === "admin" || role === "employer" ? (
            <DashboardLayout>
              <Component {...props} />
            </DashboardLayout>
          ) : (
            <Redirect to="/" />
          )
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default AuthenticatedDashboardRoute;
