import React from "react";
import Lecture from "components/elements/Lecture";
import Section from "components/elements/Section";
import Spinner from "components/elements/Spinner";
import "../CourseEdit.css";
import useCourseCurriculum from "./useCourseCurriculum";
import CurriculumModals from "./CurriculumModals";

const CourseCurriculum = (props) => {
  const value = useCourseCurriculum(props);

  let lectureCount = 1;
  let quizCount = 1;
  let assessmentCount = 1;

  const countIncrementor = (lecValue) => {
    lecValue === "quiz"
      ? (quizCount += 1)
      : lecValue === "lecture"
      ? (lectureCount += 1)
      : (assessmentCount += 1);
  };

  return (
    <div className="curriculum">
      <div className="flex justify-content-center">
        <button className="action-btn" onClick={value.openSectionModal}>
          Add Section
        </button>

        {value.curriculumData && value.curriculumData.sections?.length > 1 && (
          <button className="action-btn" onClick={value.sortSection}>
            Sort Section
          </button>
        )}
      </div>

      <CurriculumModals value={value} />

      {value.isLoading ? (
        <Spinner />
      ) : value.isError ? (
        <div className="">OOPS! Something has gone wrong!!</div>
      ) : value.curriculumData && value.curriculumData.sections.length !== 0 ? (
        value.curriculumData.sections.map((section, sectionIndex) => (
          <div className="curriculum-card" key={section._id}>
            <Section
              section={section}
              index={sectionIndex}
              openLectureModal={value.openLectureModal}
              onEditClick={value.onEditSectionClickHandler}
              onDeleteClick={value.onDeleteSectionClickHandler}
              lessonSort={() => value.sortLesson(sectionIndex)}
            />

            {section.lectures.length !== 0 &&
              section.lectures.map((lecture, lectureIndex) => (
                <React.Fragment key={lectureIndex}>
                  <Lecture
                    lecture={lecture}
                    onEditClick={value.onEditLectureClickHandler}
                    onDeleteClick={value.onDeleteLectureClickHandler}
                    lectureValue={
                      lecture.lectureType === "quiz"
                        ? quizCount
                        : lecture.lectureType === "lecture"
                        ? lectureCount
                        : assessmentCount
                    }
                  />
                  {countIncrementor(lecture.lectureType)}
                </React.Fragment>
              ))}
          </div>
        ))
      ) : (
        <div className="">Start adding content to your course</div>
      )}
    </div>
  );
};

export default CourseCurriculum;
