import React from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getUsers, statusControllerUser } from "queries/adminQueries";
const AdminUsers = () => {
  const queryClient = useQueryClient();

  const usersData = useQuery("users", getUsers);

  const editstatusMutation = useMutation(statusControllerUser, {
    onSuccess: () => {
      queryClient.invalidateQueries("users");
    },
  });

  if (usersData.isLoading) {
    return <span>Loading...</span>;
  }

  if (usersData.isError) {
    return <span>Error: {usersData.error.message}</span>;
  }
  return (
    <React.Fragment>
      <div className="flex justify-space-between p-2">
        <h1 className="dashboard-title">All Users</h1>
        {/* <button className="submit-btn" type="submit" form="form1">
                    Save
                </button> */}
      </div>{" "}
      <div className="dashboard-table">
        <table className="table table-bordere">
          <thead>
            <tr>
              {/* <th scope="col">UID</th> */}
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Status</th>
              <th scope="col">Role</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {usersData.data.map((user) => (
              <tr key={user._id}>
                {/* <th scope="row">1</th> */}
                <td>{user.firstName + " " + user.lastName}</td>
                <td>{user.email}</td>
                <td>{user.isActive === true ? "Active" : "InActive"}</td>
                <td>{user.role}</td>
                <td>
                  {/* <button className="submit-btn p-1 mr-1">
                                        Edit
                                    </button> */}
                  <button
                    className={
                      user.isActive === true
                        ? "p-1 cancel-btn"
                        : "p-1 green-btn"
                    }
                    onClick={() => {
                      editstatusMutation.mutate({
                        user_id: user._id,
                        isActive: user.isActive,
                      });
                    }}
                  >
                    {user.isActive === true ? "Deactivate" : "Activate"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default AdminUsers;
