import "./Filter.css";
import { cityDataList } from "utils/constants";
import { useState } from "react";

const Filter = ({
  category,
  loc,
  setLoc,
  setCat,
  fetch,
  cat,
  setDisplayTitle,
}) => {
  return (
    <div className="filter">
      <h1 className="mb-1">Filters</h1>
      <div className="mb-1">
        <h4>Categories</h4>
        <select
          className="classic"
          value={cat}
          onChange={async (e) => {
            await setCat(e.target.value);
            setDisplayTitle(e.target.value);
          }}
        >
          <option value="">Select Category</option>
          {category &&
            category.results.map((c) => (
              <option key={c._id} value={c.title} className="capitalize">
                {c.title.charAt(0).toUpperCase() + c.title.slice(1)}
              </option>
            ))}
        </select>
      </div>
      <div className="">
        <h4>Location</h4>
        <select
          className="classic"
          value={loc}
          onChange={(e) => setLoc(e.target.value)}
        >
          <option value="">Select Location</option>
          {cityDataList.map((l) => (
            <option value={l} key={l} className="capitalize">
              {l}
            </option>
          ))}
        </select>
      </div>
      {/* <button */}
      {/*   className="submit-btn mt-1" */}
      {/*   onClick={() => { */}
      {/*     setDisplayTitle(cat); */}
      {/*     setCat(newCat); */}
      {/*     setLoc(newLoc); */}
      {/*   }} */}
      {/* > */}
      {/*   Apply */}
      {/* </button> */}
    </div>
  );
};

export default Filter;
