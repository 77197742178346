import React from "react";
import "./AddNewCoursePage.css";
import { useForm, useFieldArray } from "react-hook-form";
import { baseURL } from "utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams, useHistory, Redirect } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import useErrorStore from "stores/ErrorStore";
import InfoWrapperInput from "components/elements/InfoWrapperInput";
import { getCategory } from "queries/categoryQueries";
import {
  addCoursePreview,
  createCourse,
  addCourseThumbnail,
} from "queries/courseQueries";
import { useState } from "react";
import { checkAuth } from "hooks/checkAuth";

const Media = (props) => {
  const { cour } = props;
  console.log("** media page **");
  console.log(cour);
  let history = useHistory();
  const queryClient = useQueryClient();
  const [disableBtn, setDisableBtn] = React.useState(false);
  const { register, handleSubmit, errors, watch, control, reset } = useForm();

  //  const [image, setImage] = React.useState({ preview: "", raw: "" });
  const [image, setImage] = React.useState();
  const handleChange = (e) => {
    if (e.target.files.length) {
      setImage(e.target.files[0]);
    }
  };

  const [isEdit, setEdit] = useState(false);

  const previewMutation = useMutation(addCoursePreview, {
    onSuccess: () => {
      queryClient.invalidateQueries("courses");
      setDisableBtn(false);
      setEdit(false);
    },
    onError: () => {
      setDisableBtn(false);
    },
  });

  const mutation = useMutation(createCourse, {
    onSuccess: () => {
      queryClient.invalidateQueries("courses");
      setDisableBtn(false);
      history.push("/admin/new-course-pricing");
    },
    onError: () => {
      setDisableBtn(false);
    },
  });

  const onSubmit = async (event) => {
    event.preventDefault();
    await checkAuth();
    console.log("test data");
    console.log(image);

    const formData = new FormData();

    formData.append("image", image);
    try {
      const result = await fetch(
        `${baseURL}/courses/add-course-thumbnail?course_id=${cour.data._id}&base_url=${baseURL}`,
        {
          method: "POST",
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          body: formData,
        }
      );
      if (result) {
        queryClient.invalidateQueries("courses");
      }
    } catch (error) {
      console.log(error);
    }

    // const data = { image: image, course_id: cour.data._id };
    // thumbnailMutation.mutate(data);
    console.log(result);
  };
  const onSubmitPreview = (data) => {
    console.log("Add Course Preview");
    console.log(data);
    const newData = { ...data, id: cour.data._id };
    console.log(newData);
    try {
      previewMutation.mutate(newData);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <main>
      <section>
        <h2 align="center">Course Media</h2>
      </section>
      <section className="course-form-wrapper">
        <form
          className="info-viewer mt-2"
          encType="multipart/form-data"
          onSubmit={onSubmit}
        >
          <InfoWrapperInput title="Course Thumbnail">
            <div className="flex-column align-items-center">
              <label
                htmlFor="upload-button"
                className="flex-column align-items-center"
              >
                {cour.data && cour.data.courseImage ? (
                  <img
                    src={cour.data.courseImage}
                    alt="dummy"
                    width="300"
                    height="300"
                  />
                ) : (
                  <>
                    <span className="fa-stack fa-2x mt-3 mb-2  ">
                      <i className="fas fa-circle fa-stack-2x" />
                      <i className="far fa-image fa-stack-1x fa-inverse" />
                    </span>
                    <h5 className="text-center">Upload your photo</h5>
                  </>
                )}
              </label>
              <input
                type="file"
                id="upload-button"
                // style={{ display: "none" }}
                onChange={handleChange}
              />
              <br />
              <button type="submit" className="submit-btn">
                Save Thumbnail
              </button>
            </div>
          </InfoWrapperInput>
        </form>
        <form
          className="info-viewer mt-2"
          onSubmit={handleSubmit(onSubmitPreview)}
        >
          <InfoWrapperInput title="Coruse Preview URL">
            <div className="wrap-input">
              <input
                className="input"
                id="coursePreview"
                name="coursePreview"
                placeholder="Enter your Course Preview URL"
                defaultValue={cour.data.corusePreview}
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
            </div>
            {errors.coursePreview && (
              <span className="is-danger">{errors.coursePreview.message}</span>
            )}
          </InfoWrapperInput>

          <div className="flex justify-content-end">
            <button
              className="submit-btn m-0 p-1"
              type="submit"
              disabled={disableBtn}
            >
              {cour.data.corusePreview ? "Update URL" : "Save"}
            </button>
          </div>
        </form>
      </section>
    </main>
  );
};

export default Media;
