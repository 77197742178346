import "views/dashboard/course/CourseEdit.css";

const Section = (props) => {
  const {
    section,
    index,
    onDragEnter,
    lessonSort,
    openLectureModal,
    onEditClick,
    onDeleteClick,
  } = props;
  return (
    <div className="curriculum-section" onDragEnter={onDragEnter}>
      <div className="flex justify-content-center">
        {section.lectures.length > 1 ? (
          <button className="action-btn" onClick={lessonSort}>
            Sort Lecture
          </button>
        ) : null}
        <button
          className="action-btn"
          onClick={() => openLectureModal(section, "lecture")}
        >
          Add Lecture
        </button>
        {/* <button */}
        {/*   className="action-btn" */}
        {/*   onClick={() => openLectureModal(section, "quiz")} */}
        {/* > */}
        {/*   Add Quiz */}
        {/* </button> */}
      </div>
      <div className="flex justify-space-between align-items-center">
        <span>
          Section {index + 1}: {section.sectionTitle}
        </span>

        <div className="hidden">
          <button className="btn font-btn" onClick={() => onEditClick(section)}>
            <i className="far fa-edit"></i>
          </button>
          <button
            className="btn font-btn"
            onClick={() => onDeleteClick(section)}
          >
            <i className="far fa-trash-alt"></i>
          </button>
        </div>
      </div>
    </div>
  );
};
export default Section;
