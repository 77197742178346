import React from "react";
import "./AddNewCoursePage.css";
import { useForm, useFieldArray } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams, useHistory, Redirect } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import useErrorStore from "stores/ErrorStore";
import InfoWrapperInput from "components/elements/InfoWrapperInput";
import { getCompleteCategory } from "queries/categoryQueries";
import { createCourse } from "queries/courseQueries";

const AddNewCoursePageOne = () => {
  let history = useHistory();
  const queryClient = useQueryClient();
  const [disableBtn, setDisableBtn] = React.useState(false);

  const { register, handleSubmit, errors, watch, control } = useForm({
    defaultValues: {
      topics: [],
      requirements: [],
      forWhom: [],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "topics",
  });
  const {
    fields: fieldsRequirements,
    append: appendRequirement,
    remove: removeRequirement,
  } = useFieldArray({
    control,
    name: "requirements",
  });
  const {
    fields: fieldsFor,
    append: appendFor,
    remove: removeFor,
  } = useFieldArray({
    control,
    name: "forWhom",
  });
  const { isLoading, isError, data, error } = useQuery(
    "categories",
    getCompleteCategory
  );

  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  };

  const mutation = useMutation(createCourse, {
    onSuccess: () => {
      queryClient.invalidateQueries("courses");
      setDisableBtn(false);
      history.push("/admin/courses");
    },
    onError: () => {
      setDisableBtn(false);
    },
  });

  const onSubmit = async (data) => {
    setDisableBtn(true);
    try {
      console.log(data);
      await mutation.mutate({ data });
    } catch (error) {
      console.log("error");
      console.log(error);
    }
  };

  return (
    <main>
      <section className="mt-1">
        {/* <div className="step-wrapper m-2"> */}
        {/*   <div className="step blue-step" /> */}
        {/*   <div className="step gray-step" /> */}
        {/*   <div className="step gray-step" /> */}
        {/* </div> */}
        <h2 align="center">Course Information</h2>
        <p align="center">Lorem Ipsum test content</p>
      </section>
      <section className="course-form-wrapper">
        <form
          className="info-viewer mt-2"
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => checkKeyDown(e)}
        >
          <InfoWrapperInput title="Course Title">
            <div className="wrap-input">
              <input
                className="input"
                id="courseTitle"
                name="courseTitle"
                placeholder="Enter your Course Title"
                /* defaultValue="2021-03-13" */
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
            </div>
            {errors.courseTitle && (
              <span className="is-danger">{errors.courseTitle.message}</span>
            )}
          </InfoWrapperInput>

          <InfoWrapperInput title="Description">
            <div className="wrap-input">
              <textarea
                className="textarea"
                id="description"
                name="description"
                placeholder="Describe your course in detail"
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              />
            </div>
            {errors.description && (
              <span className="is-danger">{errors.description.message}</span>
            )}
          </InfoWrapperInput>

          {data && (
            <InfoWrapperInput title="Category">
              <select
                className="mv-0 classic input"
                name="categoryName"
                id="categoryName"
                defaultValue="OPERATIONS"
                ref={register({
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                })}
              >
                <option value="">Select Category</option>
                {data &&
                  data.results.map((category) => (
                    <option key={category._id} value={category.title}>
                      {category.title}
                    </option>
                  ))}

                {/* <option value="Developer">Developer</option> */}
              </select>
              {errors.categoryName && (
                <span className="is-danger">{errors.categoryName.message}</span>
              )}
            </InfoWrapperInput>
          )}

          <InfoWrapperInput title="Difficulty Level">
            <select
              className="mv-0 classic input"
              name="level"
              id="level"
              defaultValue="beginner"
              ref={register({
                required: {
                  value: true,
                  message: "This field is required",
                },
              })}
            >
              <option value="">Select difficulty level</option>

              <option key="beginner" value="beginner">
                Beginner
              </option>
              <option key="intermidate" value="intermediate">
                Intermediate
              </option>
              <option key="advanced" value="advanced">
                advanced
              </option>

              {/* <option value="Developer">Developer</option> */}
            </select>
            {errors.level && (
              <span className="is-danger">{errors.level.message}</span>
            )}
          </InfoWrapperInput>

          <InfoWrapperInput title="List the topics covered in this course?">
            <div className="wrap-input">
              {fields.map(({ id, name }, index) => {
                return (
                  <div className="flex">
                    <input
                      className="input mb-0"
                      id="topics"
                      name={`topics[${index}].name`}
                      defaultValue={name}
                      placeholder="List a topic covered in course "
                      ref={register()}
                    />
                    <div
                      type="button"
                      className="m-0"
                      onClick={() => remove(index)}
                    >
                      <i className="fas fa-times"></i>
                    </div>
                  </div>
                );
              })}
              <div className="">
                <button
                  className="submit-btn ml-00 mr-auto mt-1"
                  type="button"
                  onClick={() => append({})}
                >
                  + Add Topic
                </button>
              </div>
            </div>
            {errors.learn && (
              <span className="is-danger">{errors.learn.message}</span>
            )}
          </InfoWrapperInput>

          <InfoWrapperInput title="What are the prerequsites and requirements for taking this course?">
            <div className="wrap-input">
              {fieldsRequirements.map(({ id, name }, index) => {
                return (
                  <div className="flex">
                    <input
                      className="input mb-0"
                      id="learn"
                      name={`requirements[${index}].name`}
                      defaultValue={name}
                      placeholder="List a prerequiste or requirement for the course"
                      ref={register()}
                    />
                    <div
                      type="button"
                      className="m-0"
                      onClick={() => removeRequirement(index)}
                    >
                      <i className="fas fa-times"></i>
                    </div>
                  </div>
                );
              })}
              <div className="">
                <button
                  className="submit-btn ml-00 mr-auto mt-1"
                  type="button"
                  onClick={() => appendRequirement({})}
                >
                  + Add requirements
                </button>
              </div>
            </div>
            {errors.requirements && (
              <span className="is-danger">{errors.requirements.message}</span>
            )}
          </InfoWrapperInput>

          <InfoWrapperInput title="Who is this course for?">
            <div className="wrap-input">
              {fieldsFor.map(({ id, name }, index) => {
                return (
                  <div className="flex">
                    <input
                      className="input mb-0"
                      id="forWhom"
                      name={`forWhom[${index}].name`}
                      defaultValue={name}
                      placeholder="List who is this course benfical for"
                      ref={register()}
                    />
                    <div className="m-0" onClick={() => removeFor(index)}>
                      <i className="fas fa-times"></i>
                    </div>
                  </div>
                );
              })}
              <div className="">
                <button
                  className="submit-btn ml-00 mr-auto mt-1"
                  type="button"
                  onClick={() => appendFor({})}
                >
                  + Add more response
                </button>
              </div>
            </div>
            {errors.forWhom && (
              <span className="is-danger">{errors.forWhom.message}</span>
            )}
          </InfoWrapperInput>

          <div className="flex justify-content-end">
            <Link to="/admin/courses">
              <button className="cancel-btn m-0 p-1">Cancel</button>
            </Link>
            <button
              className="submit-btn m-0 p-1"
              type="submit"
              disabled={disableBtn}
            >
              Save
            </button>
          </div>
        </form>
      </section>
    </main>
  );
};

export default AddNewCoursePageOne;
