import React, { useState } from "react";
import "views/dashboard/profile/Profile.css";
import "../Jobs.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getUsersByID, editRegisterInfo } from "queries/adminQueries";
import { Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";

import InfoWrapper from "components/elements/InfoWrapperInput";
import AddEducationModal from "views/modals/AddEducationModal";
import AddCertificatesModal from "views/modals/AddCertificates";
import AddSecondaryModal from "views/modals/AddSecondaryModal";
import AddHigherSecondaryModal from "views/modals/AddHigherSecondaryModal";
import AddSkillsModal from "views/modals/AddSkillsModal";
import DeleteModal from "views/modals/DeleteModal";
import useUserStore from "stores/UserStore";

const AddRegisterInfo = () => {
  const role = useUserStore((state) => state.role);
  const [isEdit, setIsEdit] = useState(false);
  const [mutationQueries, setMutationQueries] = useState();
  const [modalData, setModalData] = useState();
  const [educationModalInsHidden, setEducationModalIsHidden] = useState(true);
  const [deleteModalIsHidden, setDeleteModalIsHidden] = useState(true);
  const [secondaryIsHidden, setSecondaryIsHidden] = useState(true);
  const [higherSecondaryIsHidden, setHigherSecondaryIsHidden] = useState(true);
  const [certificateModalInsHidden, setCertificateModalIsHidden] =
    useState(true);
  const [skillsIsHidden, setSkillsIsHidden] = useState(true);
  const queryClient = useQueryClient();

  const { register, handleSubmit, errors } = useForm();

  const mutation = useMutation(editRegisterInfo, {
    onSuccess: () => {
      localStorage.setItem("isRegistrationComplete", true);
      useUserStore.setState({
        isRegistrationComplete: true,
      });
      queryClient.invalidateQueries("userInfo");
      // role === "user" ? history.push("/profile") : history.push("/admin");
    },
    onError: () => {},
  });

  const onSubmit = async (data) => {
    try {
      console.log(data);
      await mutation.mutate({ data });
    } catch (error) {
      console.log(error);
    }
  };

  const editSecondaryFunction = (value) => {
    setIsEdit(true);
    setModalData(value);
    setSecondaryIsHidden(false);
  };

  const deleteSecondaryFunction = () => {
    setMutationQueries("secondary");
    setDeleteModalIsHidden(false);
  };

  const editHigherSecondaryFunction = (value) => {
    setIsEdit(true);
    setModalData(value);
    setHigherSecondaryIsHidden(false);
  };

  const deleteHigherSecondaryFunction = (value) => {
    setMutationQueries("higher");
    setDeleteModalIsHidden(false);
  };

  const editEducationFunction = (value) => {
    setIsEdit(true);
    setModalData(value);
    setEducationModalIsHidden(false);
  };

  const deleteEducationFunction = (value) => {
    const newArr = data.education.slice();
    const index = newArr.findIndex((ed) => ed._id === value._id);
    newArr.splice(index, 1);
    setModalData(newArr);
    setMutationQueries("education");
    setDeleteModalIsHidden(false);
  };

  const editCertificateFunction = (value) => {
    setIsEdit(true);
    setModalData(value);
    setCertificateModalIsHidden(false);
  };

  const deleteCertificateFunction = (value) => {
    const newArr = data.certificates.slice();
    const index = newArr.findIndex((ed) => ed._id === value._id);
    newArr.splice(index, 1);
    setModalData(newArr);
    setMutationQueries("certificates");
    setDeleteModalIsHidden(false);
  };

  const editSkillFunction = (value) => {
    setIsEdit(true);
    setModalData(value);
    setSkillsIsHidden(false);
  };

  const deleteSkillFunction = (value) => {
    const newArr = data.skills.slice();
    console.log(newArr);
    const index = newArr.findIndex((ed) => ed._id === value._id);
    newArr.splice(index, 1);
    setModalData(newArr);
    setMutationQueries("skills");
    setDeleteModalIsHidden(false);
    console.log("delete");
  };
  const { isError, isLoading, data, error } = useQuery(
    "userInfo",
    getUsersByID
  );

  const skills = data && data.skills;
  const education = data && data.education;
  const certificates = data && data.certificates;
  if (isLoading) {
    return <span>Loading...</span>;
  }

  if (isError) {
    return <span>Error: {error.message}</span>;
  }
  if (data.isRegistrationComplete === true) {
    // if (role == "user") {
    return <Redirect to="/profile" />;
    // }
    // return <Redirect to="/admin" />;
  }

  return (
    <div className="main-container">
      <div className="main-display-2">
        <div className="profile">
          <div className="flex justify-space-between p-2">
            <h1 className="dashboard-title">Add Information</h1>
            {/* <button className="submit-btn" type="submit" form="form1">
                    Save
                </button> */}
          </div>
          {deleteModalIsHidden ? null : (
            <DeleteModal
              setShowModal={setDeleteModalIsHidden}
              showModal={deleteModalIsHidden}
              modalData={modalData}
              setModalData={setModalData}
              mutationQueries={mutationQueries}
              setMutationQueries={setMutationQueries}
            />
          )}
          {educationModalInsHidden ? null : (
            <AddEducationModal
              setShowModal={setEducationModalIsHidden}
              showModal={educationModalInsHidden}
              education={education}
              edit={isEdit}
              setEdit={setIsEdit}
              modalData={modalData}
              setModalData={setModalData}
            />
          )}

          {secondaryIsHidden ? null : (
            <AddSecondaryModal
              setShowModal={setSecondaryIsHidden}
              showModal={secondaryIsHidden}
              edit={isEdit}
              setEdit={setIsEdit}
              modalData={modalData}
              setModalData={setModalData}
            />
          )}

          {higherSecondaryIsHidden ? null : (
            <AddHigherSecondaryModal
              setShowModal={setHigherSecondaryIsHidden}
              showModal={higherSecondaryIsHidden}
              edit={isEdit}
              setEdit={setIsEdit}
              modalData={modalData}
              setModalData={setModalData}
            />
          )}
          {certificateModalInsHidden ? null : (
            <AddCertificatesModal
              setShowModal={setCertificateModalIsHidden}
              showModal={certificateModalInsHidden}
              certificates={certificates}
              edit={isEdit}
              setEdit={setIsEdit}
              modalData={modalData}
              setModalData={setModalData}
            />
          )}

          {skillsIsHidden ? null : (
            <AddSkillsModal
              setShowModal={setSkillsIsHidden}
              showModal={skillsIsHidden}
              skills={skills}
              edit={isEdit}
              setEdit={setIsEdit}
              modalData={modalData}
              setModalData={setModalData}
            />
          )}

          <div>
            <form className="info-viewer" onSubmit={handleSubmit(onSubmit)}>
              {role === "user" ? (
                <>
                  <InfoWrapper title="Education">
                    {data.secondaryEducation ? (
                      <div className="info-box">
                        <div>
                          <h4>Secondary (X)</h4>
                          <p>
                            {data.secondaryEducation.school},{" "}
                            {data.secondaryEducation.board}
                          </p>
                          <p>
                            Year of Completion:
                            {data.secondaryEducation.completedYear}{" "}
                          </p>
                        </div>

                        <div className="button-controller">
                          <button
                            type="button"
                            className="img-btn"
                            onClick={() => {
                              editSecondaryFunction(data.secondaryEducation);
                            }}
                          >
                            <FontAwesomeIcon icon={["far", "edit"]} />
                          </button>
                          <button
                            type="button"
                            className="img-btn"
                            onClick={() => {
                              deleteSecondaryFunction();
                            }}
                          >
                            <FontAwesomeIcon icon={["far", "trash-alt"]} />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn-outline mb-1"
                        onClick={() => {
                          setSecondaryIsHidden(false);
                        }}
                      >
                        Add Secondary Education
                      </button>
                    )}
                    <br />

                    {data.higherSecondaryEducation ? (
                      <div className="info-box">
                        <div>
                          <h4>Higher Secondary (XII)</h4>
                          <p>
                            {data.higherSecondaryEducation.college},{" "}
                            {data.higherSecondaryEducation.board}
                          </p>
                          <p>
                            Year of Completion:{" "}
                            {data.higherSecondaryEducation.completedYear}{" "}
                          </p>
                        </div>
                        <div className="button-controller">
                          <button
                            type="button"
                            className="img-btn"
                            onClick={() => {
                              editHigherSecondaryFunction(
                                data.higherSecondaryEducation
                              );
                            }}
                          >
                            <FontAwesomeIcon icon={["far", "edit"]} />
                          </button>
                          <button
                            type="button"
                            className="img-btn"
                            onClick={() => {
                              deleteHigherSecondaryFunction();
                            }}
                          >
                            <FontAwesomeIcon icon={["far", "trash-alt"]} />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn-outline mb-1"
                        onClick={() => {
                          setHigherSecondaryIsHidden(false);
                        }}
                      >
                        Add Higher Secondary Education
                      </button>
                    )}
                    <br />

                    <div className="education">
                      {data.education.length !== 0
                        ? data.education.map((ed) => (
                            <div key={ed._id} className="info-box">
                              <div className="mb-0">
                                <h4>
                                  {ed.degree}
                                  {ed.stream ? (
                                    <span>, {ed.stream}</span>
                                  ) : null}
                                </h4>
                                <p>
                                  {ed.college}, {ed.university}
                                </p>
                                <p>
                                  {ed.startYear}-{ed.endYear}
                                </p>
                                {ed.percentage ? (
                                  <p>Percentage: {ed.percentage}</p>
                                ) : null}
                              </div>
                              <div className="button-controller">
                                <button
                                  type="button"
                                  value={ed._id}
                                  className="img-btn"
                                  onClick={() => {
                                    editEducationFunction(ed);
                                  }}
                                >
                                  <FontAwesomeIcon icon={["far", "edit"]} />
                                </button>
                                <button
                                  type="button"
                                  value={ed._id}
                                  className="img-btn"
                                  onClick={() => {
                                    deleteEducationFunction(ed);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={["far", "trash-alt"]}
                                  />
                                </button>
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                    <button
                      type="button"
                      className="btn-outline mb-1"
                      onClick={() => {
                        setEducationModalIsHidden(false);
                      }}
                    >
                      Add Qualification
                    </button>
                    {/* </div> */}
                  </InfoWrapper>

                  <InfoWrapper title="Certificates">
                    {data.certificates.length !== 0
                      ? data.certificates.map((ed) => (
                          <div key={ed._id} className="info-box">
                            <div className="mb-0">
                              <h4>{ed.institute}</h4>
                              <p>{ed.organization}</p>
                              <p>
                                {new Date(ed.startDate).toLocaleDateString()}-
                                {new Date(ed.endDate).toLocaleDateString()}
                              </p>
                              {ed.description ? (
                                <p>Description: {ed.description}</p>
                              ) : null}
                            </div>
                            <div className="button-controller">
                              <button
                                type="button"
                                value={ed._id}
                                className="img-btn"
                                onClick={() => {
                                  editCertificateFunction(ed);
                                }}
                              >
                                <FontAwesomeIcon icon={["far", "edit"]} />
                              </button>
                              <button
                                type="button"
                                value={ed._id}
                                className="img-btn"
                                onClick={() => {
                                  deleteCertificateFunction(ed);
                                }}
                              >
                                <FontAwesomeIcon icon={["far", "trash-alt"]} />
                              </button>
                            </div>
                          </div>
                        ))
                      : null}
                    <button
                      type="button"
                      className="btn-outline mb-1"
                      onClick={() => {
                        setCertificateModalIsHidden(false);
                      }}
                    >
                      Add Certificates
                    </button>
                  </InfoWrapper>

                  <InfoWrapper title="Skills">
                    {data.skills.length !== 0 ? (
                      data.skills.map((skill) => (
                        <div key={skill._id} className="info-box">
                          <div className="mb-0">
                            <h4>{skill.skill}</h4>
                            <p>{skill.expertise}</p>
                          </div>
                          <div className="button-controller">
                            <button
                              className="img-btn"
                              type="button"
                              value={skill._id}
                              onClick={() => {
                                editSkillFunction(skill);
                              }}
                            >
                              <FontAwesomeIcon icon={["far", "edit"]} />
                            </button>
                            <button
                              type="button"
                              value={skill._id}
                              className="img-btn"
                              onClick={() => {
                                deleteSkillFunction(skill);
                              }}
                            >
                              <FontAwesomeIcon icon={["far", "trash-alt"]} />
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="mb-0">
                        * Atleast one skill is required
                      </div>
                    )}
                    <button
                      type="button"
                      className="btn-outline mb-1"
                      onClick={() => {
                        setSkillsIsHidden(false);
                      }}
                    >
                      Add Skills
                    </button>
                  </InfoWrapper>

                  <InfoWrapper title="Additional Information" optional="true">
                    <textarea
                      className="textarea"
                      id="bio"
                      name="bio"
                      defaultValue={data.bio}
                      ref={register()}
                    />
                    {errors.bio && (
                      <div className="is-danger">{errors.bio.message}</div>
                    )}
                  </InfoWrapper>
                  <div className="flex justify-content-end">
                    <button
                      className="submit-btn m-0 p-1"
                      type="submit"
                      disabled={data.skills.length === 0 ? true : false}
                    >
                      Save
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <InfoWrapper title="Company Name">
                    <div className="wrap-input">
                      <input
                        className="input"
                        id="companyName"
                        name="companyName"
                        placeholder="Enter your Company Name"
                        defaultValue={data.companyName}
                        ref={register({
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                        })}
                      />
                    </div>
                    {errors.companyName && (
                      <span className="is-danger">
                        {errors.companyName.message}
                      </span>
                    )}
                  </InfoWrapper>

                  <InfoWrapper title="Company Locotion">
                    <div className="wrap-input">
                      <input
                        className="input"
                        id="companyLocation"
                        name="companyLocation"
                        placeholder="Enter your Company Locotion"
                        defaultValue={data.companyLocation}
                        ref={register({
                          required: {
                            value: true,
                            message: "This field is required",
                          },
                        })}
                      />
                    </div>
                    {errors.companyLocation && (
                      <span className="is-danger">
                        {errors.companyLocation.message}
                      </span>
                    )}
                  </InfoWrapper>
                  <InfoWrapper title="About Company">
                    <textarea
                      className="textarea"
                      id="bio"
                      name="bio"
                      placeholder="Brief description about company"
                      defaultValue={data.bio}
                      ref={register({
                        required: {
                          value: true,
                          message: "This field is required",
                        },
                      })}
                    />
                    {errors.bio && (
                      <div className="is-danger">{errors.bio.message}</div>
                    )}
                  </InfoWrapper>

                  <InfoWrapper title="Website" optional="true">
                    <div className="wrap-input">
                      <input
                        className="input"
                        id="website"
                        name="website"
                        placeholder="Enter your Website URL"
                        defaultValue={data.website}
                        ref={register()}
                      />
                    </div>
                    {errors.website && (
                      <span className="is-danger">
                        {errors.website.message}
                      </span>
                    )}
                  </InfoWrapper>

                  <InfoWrapper title="GSTIN" optional="true">
                    <div className="wrap-input">
                      <input
                        className="input"
                        id="gstin"
                        name="gstin"
                        placeholder="Enter your gstin"
                        defaultValue={data.gstin}
                        ref={register()}
                      />
                    </div>
                    {errors.gstin && (
                      <span className="is-danger">{errors.gstin.message}</span>
                    )}
                  </InfoWrapper>
                  <div className="flex justify-content-end">
                    <button className="submit-btn m-0 p-1" type="submit">
                      Save
                    </button>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddRegisterInfo;
